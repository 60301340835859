<template>
	<div class="absolute full-width full-height">
		<div v-if="loading" class="m-t-xl">
			<Loader size="40" />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert type="danger" :message="error" />
		</div>
		<div v-else class="full-width full-height">
			<div v-if="tickets.length == 0" class="p-sm">
				<Alert type="info" message="ticketing.no_tickets_found" />
			</div>
			<DataTable
				v-else
				:items="tickets"
				:items_per_page="50"
				:fields="table_columns"
			>
				<template #cell(status)="status">
					<TicketStatus :id="status.value" />
				</template>

				<template #cell(description)="description">
					<div class="ticket-list-ticket-description">
						{{ description.value }}
					</div>
				</template>

				<template #cell(faulty_equipments)="faulty_equipments">
					<div v-if="faulty_equipments.value">
						<div
							v-for="(item, index) in faulty_equipments.value"
							v-bind:key="'tickets_' + index + '_equipments_' + index"
							v-bind:class="{
								'line-height-xxl': faulty_equipments.value.length > 1
							}"
						>
							<span v-if="equipments[item]">{{ equipments[item].name }}</span>
						</div>
					</div> </template
				>a

				<template #cell(created_by)="created_by">
					<TicketUserName
						v-if="created_by.value"
						:requested_user_id="created_by.value"
					/>
				</template>

				<template #cell(assigned_to)="assigned_to">
					<TicketUserName
						v-if="assigned_to.value"
						:requested_user_id="assigned_to.value"
					/>
				</template>

				<template #cell(options)="data">
					<div class="relative">
						<div class="cursor-pointer" v-on:click="options_id = data.item.id">
							<Icon icon="ellipsis-v" />
						</div>
						<div
							v-if="options_id && options_id == data.item.id"
							class="tickets-options overflow-hidden box-shadow1 white-bg"
						>
							<router-link
								:to="{
									name: 'view-ticket',
									params: { ticket_id: data.item.id }
								}"
							>
								<div class="p-w-md white-bg">
									<span>{{ $t("general.view") }}</span>
								</div>
							</router-link>
							<router-link
								:to="{
									name: 'edit-ticket',
									params: { ticket_id: data.item.id }
								}"
							>
								<div class="p-w-md white-bg">
									<span>{{ $t("general.update") }}</span>
								</div>
							</router-link>
							<router-link
								v-if="user_can_remove_ticket"
								:to="{
									name: 'remove-ticket',
									params: { ticket_id: data.item.id }
								}"
							>
								<div class="p-w-md max3-background">
									<span>{{ $t("general.delete") }}</span>
								</div>
							</router-link>
						</div>
					</div>
				</template>
			</DataTable>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import DataTable from "@sub_components/DataTable.vue";
import TicketStatus from "./sub-components/TicketStatus";
import TicketUserName from "./sub-components/TicketUserName";

import moment from "moment";

import TicketServiceClass from "@services/user-services/ticket-service";
import AssetsFinderClass from "@src/asset/application/assets-finder";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const TicketService = new TicketServiceClass();
const AssetsFinder = new AssetsFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "TicketingList",
	props: ["refresh", "filter_params"],
	components: {
		Alert,
		DataTable,
		Loader,
		TicketStatus,
		TicketUserName
	},
	data() {
		return {
			loading: false,
			error: null,
			tickets: [],
			options_id: null,
			equipments: null,
			user_can_remove_ticket: UserPermissionsUtil.can_user("remove_ticket")
		};
	},
	async created() {
		this.loading = true;
		try {
			await this.get_equipments();
			await this.refresh_tickets();
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = null;
		}
	},
	methods: {
		async refresh_tickets() {
			this.loading = true;

			try {
				let tickets = await TicketService.get_tickets(
					this.filter_params && this.filter_params.locations
						? this.filter_params.locations
						: null,
					this.filter_params && this.filter_params.created_by
						? this.filter_params.created_by
						: null,
					this.filter_params && this.filter_params.assigned_to
						? this.filter_params.assigned_to
						: null,
					this.filter_params && this.filter_params.status
						? this.filter_params.status
						: null,
					this.filter_params && this.filter_params.equipments
						? this.filter_params.equipments
						: null
				);

				for (let index in tickets) {
					let ticket = tickets[index];
					const ticket_location = await AssetsFinder.get_asset_by_id(
						ticket.asset_id
					);

					if (ticket_location) {
						ticket.location_name = ticket_location.name;
						ticket.location_custom_id = ticket_location.custom_id || "-";
						ticket.location_current_year =
							ticket_location.plant_data &&
							ticket_location.plant_data.commissioning_date
								? moment().diff(
										ticket_location.plant_data.commissioning_date,
										"years"
								  )
								: null;
					}
				}

				this.tickets = tickets;
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = null;
			}
		},
		async get_equipments() {
			this.equipments = await TicketService.get_ticketing_faulty_equipments();
		}
	},
	computed: {
		table_columns() {
			return [
				{
					key: "location_custom_id",
					label: this.$t("ticketing.location_id")
				},
				{
					key: "location_name",
					label: this.$t("location.name")
				},
				{
					key: "status",
					label: this.$t("general.status")
				},
				{
					key: "title",
					label: this.$t("ticketing.ticket_entity.title")
				},
				{
					key: "description",
					label: this.$t("general.description")
				},
				{
					key: "faulty_equipments",
					label: this.$t("ticketing.ticket_entity.faulty_equipments")
				},
				{
					key: "location_current_year",
					label: this.$tc("general.year", 1)
				},
				{
					key: "created_by",
					label: this.$t("ticketing.ticket_entity.created_by")
				},
				{
					key: "assigned_to",
					label: this.$t("ticketing.ticket_entity.assigned_to")
				},
				{
					key: "options"
				}
			];
		}
	},
	watch: {
		refresh: function () {
			this.refresh_tickets();
		}
	}
};
</script>

<style scoped>
#ticket-list-table {
	z-index: 1;
	height: calc(100% - 65px);
}
.tickets-options {
	position: absolute;
	top: 20px;
	right: 40px;
	border-radius: 10px;
	line-height: 50px;
	font-size: 18px;
	z-index: 1;
}
.ticket-list-ticket-description {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 500px;
}
#paginator {
	position: absolute;
	width: 100%;
	bottom: 15px;
	z-index: 0;
}
</style>
