import UserUnitSystems from "@entities/user-unit-systems";
import Timezones from "@entities/timezones";
import ForecastVariables from "@src/asset/domain/forecast/forecast-variables";
import EnergyDataProviders from "@entities/energy-measurement-providers";

import UserFinderClass from "@src/user/application/user-finder.js";

import EnergySystemFacilityOptions from "@src/energy-system/domain/entities/facility-options";
import EnergySystemSolarOptions from "@src/energy-system/domain/entities/solar-options";
import EnergySystemTrackingOptions from "@src/energy-system/domain/entities/tracking-options";
import EnergySystemConnectionTypeOptions from "@src/energy-system/domain/entities/connection-type-options";

export default class {
	constructor() {
		this.entity_files = {
			timezones: Timezones,
			energy_data_providers: EnergyDataProviders
		};
		this.multilingual_entity_files = {
			user_unit_systems: UserUnitSystems,
			energy_system_facility_options: EnergySystemFacilityOptions,
			energy_system_solar_options: EnergySystemSolarOptions,
			energy_system_tracking_options: EnergySystemTrackingOptions,
			connection_type_options: EnergySystemConnectionTypeOptions
		};

		const UserFinder = new UserFinderClass();
		const current_user = UserFinder.get_stored_user();
		this.unit_system = current_user ? current_user.unit_system : "si";
	}

	get_entity(entity_name) {
		switch (entity_name) {
			case "forecast_variables":
				return this._get_formated_forecast_variables();
				break;
			default:
				const entity_data = this.entity_files[entity_name];

				return entity_data;
		}
	}

	get_multilingual_entity(entity_name) {
		const entity_data = this.multilingual_entity_files[entity_name];

		return entity_data;
	}

	_get_formated_forecast_variables() {
		const raw_data = ForecastVariables;
		let formatted_data = {};

		for (let variable_slug in raw_data) {
			let variable_data = Object.assign({}, raw_data[variable_slug]);

			if (
				variable_data.unit != null &&
				typeof variable_data.unit !== "string"
			) {
				variable_data.unit = variable_data.unit[this.unit_system];
			}

			formatted_data[variable_slug] = variable_data;
		}

		return formatted_data;
	}
}
