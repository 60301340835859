<template>
	<div class="flex-auto-full-height-content white-bg rounded-s p-sm gap-s">
		<div class="header">
			<span class="font-l font-700">{{ $t("dashboard.active_alerts") }}</span>
		</div>
		<div class="content">
			<Loader v-if="loading" size="30" class="full-width full-height" />
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<div
				v-else-if="pie_chart_data"
				class="display-flex-space-between full-height"
			>
				<div class="flex-vertical-center full-height">
					<PieChart
						:data="pie_chart_data"
						:hide_legend="true"
						:hide_tooltips="true"
						:hide_datalabels="true"
						:height_in_px="200"
						:is_donut="true"
						:donut_middle_label="$t('dashboard.summary.notifications')"
						donut_middle_text_color="var(--grey1)"
					/>
				</div>

				<div class="flex-vertical-center">
					<div class="display-grid gap-m">
						<div v-for="item in pie_chart_data" class="display-flex gap-s">
							<div
								class="full-rounded m-t-xs"
								style="width: 15px; height: 15px"
								:style="{ 'background-color': item.color }"
							></div>
							<div class="grey1-color">
								<div class="font-700 font-xl">
									{{ item.value }}
								</div>
								<div class="font-s no-wrap">
									{{ item.label }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PieChart from "@sub_components/charts/PieChart.vue";

import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder.js";
import SystemsAlertsFinderClass from "@src/alert/application/systems-alerts-finder.js";

const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();
const SystemsAlertsFinder = new SystemsAlertsFinderClass();

export default {
	name: "AssetCurrentDayAlertsSummary",
	components: {
		Loader,
		Alert,
		PieChart
	},
	props: {
		asset_id: String
	},
	data() {
		return {
			loading: true,
			error: null,
			current_day_alerts: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_energy_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(this.asset_id);

			if (
				!asset_energy_systems ||
				Object.keys(asset_energy_systems).length == 0
			) {
				return;
			}

			const asset_energy_systems_ids = asset_energy_systems.map(
				(energy_system) => energy_system.id
			);

			const energy_systems_alerts =
				await SystemsAlertsFinder.get_systems_alerts({
					date_from: new Date(),
					date_to: new Date(),
					is_archived: false,
					energy_systems: asset_energy_systems_ids
				});

			this.current_day_alerts = {
				pr_alert: 0,
				provider_alert: 0,
				suspicious_data_alert: 0
			};

			energy_systems_alerts.data.forEach((alert) => {
				this.current_day_alerts[alert.alert_type] += 1;
			});
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		pie_chart_data() {
			if (!this.current_day_alerts) return null;

			return [
				{
					label: this.$t("entities.alert.alert_type_options.provider_alert"),
					color: "var(--blue1)",
					value: this.current_day_alerts.provider_alert
				},
				{
					label: this.$t("entities.alert.alert_type_options.pr_alert"),
					color: "var(--warning-color)",
					value: this.current_day_alerts.pr_alert
				},
				{
					label: this.$t(
						"entities.alert.alert_type_options.suspicious_data_alert"
					),
					color: "var(--grey2)",
					value: this.current_day_alerts.suspicious_data_alert
				}
			];
		}
	}
};
</script>
