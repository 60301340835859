<template lang="html">
	<div>
		<div>
			<span>{{ $t("entities.energy_provider_auth.auth.username") }}</span>
			<TextInput
				class="m-t-xs"
				v-model="username"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>
		<div class="m-t">
			<span>{{ $t("entities.energy_provider_auth.auth.password") }}</span>
			<TextInput
				class="m-t-xs"
				v-model="password"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";

export default {
	name: "TwinmeterLoginForm",
	props: { editing: Boolean },
	components: {
		TextInput
	},
	data() {
		return {
			username: null,
			password: null
		};
	},
	methods: {
		emit_value() {
			this.$emit("input", {
				username: this.username,
				password: this.password
			});
		}
	},
	watch: {
		username: function () {
			this.emit_value();
		},
		password: function () {
			this.emit_value();
		}
	}
};
</script>

<style lang="css" scoped></style>
