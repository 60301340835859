import AssetsGroupSummaryFinderInterfaceClass from "../infrastructure/assets-group-summary-finder-interface";
import AssetsGroupSummaryFinderValidator from "../domain/assets-group-summary-finder-validator";
import StoreUtilClass from "@src/shared/application/utils/store-util";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.validator = AssetsGroupSummaryFinderValidator;
		this.backend_interface = new AssetsGroupSummaryFinderInterfaceClass();
		this.store_util = new StoreUtilClass("assets_group_summary");
	}

	async get_default_assets_group_summary() {
		try {
			var cached_asset = this.store_util.get_data();
			if (cached_asset) return cached_asset;

			const interface_result =
				await this.backend_interface.get_default_assets_group_summary();
			const validated_assets_group_summary =
				this.validator.validate(interface_result);

			this.store_util.set_data(
				validated_assets_group_summary,
				moment().add(1, "hours").toDate()
			);

			return this.store_util.get_data();
		} catch (e) {
			throw new AppError(
				"error_get_default_assets_group_summary",
				"general.errors.general_error",
				e
			);
		}
	}
}
