<template lang="html">
	<div>
		<div v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else class="m-t">
			<LineChart
				:series="chart_series"
				:variables_data="chart_used_variables"
				:default_visible_variables="[
					'temperature',
					'ghi',
					'pv_energy_production'
				]"
				:height="height_in_px + 'px'"
				:display_minichart="true"
			/>
		</div>
	</div>
</template>

<script>
import LineChart from "@components/sub_components/charts/LineChart2";
import Loader from "@loader";
import Alert from "@alert";

import moment from "moment";
import EntityServiceClass from "@services/entity-service";
import AssetWeatherForecastFinderClass from "@src/asset/application/forecast/asset-weather-forecast-finder";
import AssetFinderClass from "@src/asset/application/asset-finder";
import AssetEnergyForecastFinderClass from "@src/forecast/application/asset-energy-forecast-finder";

const EntityService = new EntityServiceClass();
const AssetWeatherForecastFinder = new AssetWeatherForecastFinderClass();
const AssetFinder = new AssetFinderClass();
const AssetEnergyForecastFinder = new AssetEnergyForecastFinderClass();

export default {
	name: "AssetForecastChart",
	components: {
		Loader,
		Alert,
		LineChart
	},
	props: {
		asset_id: String,
		height_in_px: Number
	},
	data() {
		return {
			loading: true,
			error: null,

			chart_series: null,
			chart_used_variables: {}
		};
	},
	async created() {
		this.loading = true;

		try {
			await this.load_location_weather_forecast();

			const asset = await AssetFinder.get_asset(this.asset_id);
			if (asset.features.has_pv_production) {
				await this.load_asset_energy_production_forecast(this.asset_id);
			}
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async load_location_weather_forecast() {
			const location_weather_forecast =
				await AssetWeatherForecastFinder.get_asset_weather_forecast(
					this.asset_id
				);
			this.chart_series = location_weather_forecast.data;

			const weather_forecast_variables_data =
				EntityService.get_entity("forecast_variables");
			const first_weather_forecast = this.chart_series[0];
			for (let variable_slug in first_weather_forecast) {
				if (variable_slug == "time") continue;

				if (weather_forecast_variables_data[variable_slug])
					this.chart_used_variables[variable_slug] =
						weather_forecast_variables_data[variable_slug];
			}
		},
		async load_asset_energy_production_forecast(asset_id) {
			const energy_production_forecast =
				await AssetEnergyForecastFinder.get_asset_energy_forecast(asset_id);
			if (!energy_production_forecast) return false;

			const max_energy_production_forecast_value =
				energy_production_forecast.reduce((p, c) =>
					p.energy_production > c.energy_production ? p : c
				).energy_production;
			const convert_into_wh = max_energy_production_forecast_value < 1;

			energy_production_forecast.forEach((item) => {
				var chart_series_time_data = this.chart_series.find((serie_item) =>
					moment(serie_item.time).isSame(item.time, "minute")
				);

				if (chart_series_time_data) {
					chart_series_time_data.pv_energy_production = convert_into_wh
						? item.energy_production * 1000
						: item.energy_production;
				}
			});

			const forecast_variables_data =
				EntityService.get_entity("forecast_variables");

			this.chart_used_variables["pv_energy_production"] =
				forecast_variables_data["pv_energy_production"];

			if (convert_into_wh)
				this.chart_used_variables["pv_energy_production"].unit = "Wh";
		}
	}
};
</script>
