<template lang="html">
	<div>
		<div>
			<span>
				{{ $t("entities.energy_provider_auth.auth.username") }}
			</span>
			<TextInput
				class="m-t-xs"
				v-model="username"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>
		<div class="m-t">
			<span>
				{{ $t("entities.energy_provider_auth.auth.password") }}
			</span>
			<TextInput
				class="m-t-xs"
				v-model="password"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>

		<div class="m-t">
			<span>
				{{ $t("entities.energy_provider_auth.auth.app_key") }}
			</span>
			<TextInput
				class="m-t-xs"
				v-model="app_key"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>

		<div class="m-t">
			<span>
				{{ $t("entities.energy_provider_auth.auth.x_access_key") }}
			</span>
			<TextInput
				class="m-t-xs"
				v-model="x_access_key"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";

export default {
	name: "SungrowLoginForm",
	props: { editing: Boolean },
	components: {
		TextInput
	},
	data() {
		return {
			username: null,
			password: null,
			app_key: null,
			x_access_key: null
		};
	},
	methods: {
		emit_value() {
			this.$emit("input", {
				username: this.username,
				password: this.password,
				app_key: this.app_key,
				x_access_key: this.x_access_key
			});
		}
	},
	watch: {
		username: function () {
			this.emit_value();
		},
		password: function () {
			this.emit_value();
		},
		app_key: function () {
			this.emit_value();
		},
		x_access_key: function () {
			this.emit_value();
		}
	}
};
</script>

<style lang="css" scoped></style>
