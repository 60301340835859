<template lang="html">
	<div>
		<div>
			<span>{{ $t("entities.energy_provider_auth.auth.token") }}</span>
			<TextInput
				class="m-t-xs"
				v-model="token"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";

export default {
	name: "GrowattLoginForm",
	props: { editing: Boolean },
	components: {
		TextInput
	},
	data() {
		return {
			token: null
		};
	},
	methods: {
		emit_value() {
			this.$emit("input", {
				token: this.token
			});
		}
	},
	watch: {
		token: function () {
			this.emit_value();
		}
	}
};
</script>

<style lang="css" scoped></style>
