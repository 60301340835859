import EnergySystemCustomConfigFinderInterfaceClass from "../infrastructure/energy-system-custom-config-finder-interface.js";
import EnergySystemCustomConfigValidator from "../domain/energy-system-custom-config-validator";

import EnergySystemsCustomConfigStoreUtil from "./utils/energy-systems-custom-config-store-util.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new EnergySystemCustomConfigFinderInterfaceClass();
		this.store_util = EnergySystemsCustomConfigStoreUtil;
		this.validator = EnergySystemCustomConfigValidator;
	}

	async get_energy_system_custom_config(user_id, energy_system_id) {
		const stored_item = await this.store_util.get_cached_item_by_id(
			energy_system_id
		);
		if (stored_item) return stored_item.data;

		try {
			const interface_data =
				await this.backend_interface.get_energy_system_custom_config(
					user_id,
					energy_system_id
				);
			const validated_data = this.validator.validate(interface_data);

			await this.store_util.add_or_update_item({
				energy_system_id: energy_system_id,
				data: validated_data
			});

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_energy_system_custom_config",
				"general.errors.general_error",
				e
			);
		}
	}
}
