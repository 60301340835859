import BackendInterface from "@src/shared/infrastructure/backend-interface";
import DataFormatterService from "../../../libs/application/data-formatter-service";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
		this.data_formatter_service = new DataFormatterService();
	}

	async get_asset_monthly_pr(asset_id, from, to) {
		const url = "/api/asset/" + asset_id + "/pr-data/monthly";
		const result = await this.get(url, {
			query_params: {
				from: moment(from).format("YYYY-MM"),
				to: moment(to).format("YYYY-MM")
			}
		});

		return result.map((item) => {
			return {
				pr: this.data_formatter_service.is_number(item.pr)
					? item.pr * 100
					: null,
				month: new Date(item.month)
			};
		});
	}
}
