import AssetsCreatorInterfaceClass from "@src/asset/infrastructure/asset-creator-interface";
import AssetFinderValidator from "@src/asset/domain/asset-finder-validator";
import AssetCreatorValidator from "@src/asset/domain/asset-creator-validator";

import AssetsLastUpdateUtil from "./utils/assets-last-update-util";
import AssetsStoreUtil from "./utils/assets-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetsCreatorInterfaceClass();
		this.create_validator = AssetCreatorValidator;
		this.finder_validator = AssetFinderValidator;

		this.assets_last_update_util = new AssetsLastUpdateUtil();
		this.store_util = AssetsStoreUtil;
	}

	async create_asset(asset_to_create) {
		const validated_asset_to_create =
			this.create_validator.validate(asset_to_create);

		try {
			const interface_result = await this.backend_interface.create_asset(
				validated_asset_to_create
			);

			const validated_asset = this.finder_validator.validate(interface_result);

			this.store_util.add_or_update_item(validated_asset);
			this.assets_last_update_util.set_assets_last_update();

			return validated_asset;
		} catch (e) {
			throw new AppError(
				"error_create_asset",
				"general.errors.general_error",
				e
			);
		}
	}
}
