<template>
	<div class="gray-bg full-width full-height absolute p-b p-t-l p-l p-r">
		<div id="content">
			<div>
				<div class="p-l p-r-xl">
					<div id="profile-back-button" class="flex-center-content">
						<router-link :to="{ name: 'app' }">
							<div>
								<Icon icon="chevron-left" size="18" />
							</div>
						</router-link>
					</div>

					<div v-if="user">
						<div class="flex-center-content">
							<UserAvatar :user="user" size="100" edit="true" />
						</div>

						<div class="m-t font-bold font-l">
							<span>{{ user.first_name }} {{ user.last_name }}</span>
						</div>
					</div>

					<div class="hide-xs m-t-xl">
						<div
							class="m-t-md"
							v-for="(item, index) in menu"
							v-bind:key="'settings_menu_' + index"
						>
							<router-link
								:to="{ name: item.link_name }"
								class="light-color2 font-l"
								v-bind:class="{
									'primary-color': route_name == item.link_name
								}"
								>{{ item.label }}</router-link
							>
						</div>
					</div>
				</div>
			</div>
			<div>
				<router-view
					class="full-width full-height full-height relative overflow-hidden"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import UserAvatar from "@sub_components/UserAvatar";

import UserFinderClass from "@src/user/application/user-finder.js";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const UserFinder = new UserFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "User",
	components: {
		UserAvatar
	},
	data() {
		return {
			user: null
		};
	},
	async created() {
		this.user = await UserFinder.get_user();
	},
	methods: {
		can_user(permission) {
			return UserPermissionsUtil.can_user(permission);
		}
	},
	computed: {
		route_name() {
			return this.$route.name;
		},
		menu() {
			let menu = [
				{
					label: this.$t("user_settings.menu.settings"),
					link_name: "user-settings"
				}
			];

			if (this.can_user("edit_energy_provider_auth"))
				menu.push({
					label: this.$t("user_settings.menu.energy_providers_integrations"),
					link_name: "user-providers"
				});

			if (this.can_user("manage_user_energy_systems"))
				menu.push({
					label: this.$t("user_settings.menu.system_manager"),
					link_name: "user-energy-systems"
				});

			if (this.can_user("edit_ticketing_parameters"))
				menu.push({
					label: this.$t("user_settings.menu.ticketing"),
					link_name: "ticketing-settings"
				});

			if (this.can_user("have_email_notifications"))
				menu.push({
					label: this.$t("user_settings.menu.email_notifications_settings"),
					link_name: "email-notifications-settings"
				});

			if (this.can_user("manage_users"))
				menu.push({
					label: this.$t("user_settings.menu.users"),
					link_name: "owned-users"
				});

			menu.push({
				label: this.$t("header.logout"),
				link_name: "logout"
			});

			return menu;
		}
	}
};
</script>

<style lang="css" scoped>
#content {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: max-content auto;
}
#profile-back-button {
	width: 30px;
	height: 30px;
	background-color: white;
	box-shadow: 0px 3px 17px grey;
	border-radius: 50%;
	color: black !important;
}

/*.user-new-popup {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 3;
}
.user-new-popup > div {
	top: 25px;
	width: 450px;
	left: calc(50% - 225px);
	max-height: calc(100% - 50px);
}*/
</style>
