<template lang="html">
	<div>
		<div>
			<span>{{ $t("entities.energy_provider_auth.auth.user_id") }}</span>
			<TextInput
				class="m-t-xs"
				v-model="user_id"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>
		<div class="m-t">
			<span>{{ $t("entities.energy_provider_auth.auth.user_key") }}</span>
			<TextInput
				class="m-t-xs"
				v-model="user_key"
				:disabled="editing"
				:placeholder="editing ? '******' : null"
			/>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";

export default {
	name: "FroniusLoginForm",
	props: { editing: Boolean },
	components: {
		TextInput
	},
	data() {
		return {
			user_id: null,
			user_key: null
		};
	},
	methods: {
		emit_value() {
			this.$emit("input", {
				user_id: this.user_id,
				user_key: this.user_key
			});
		}
	},
	watch: {
		user_id: function () {
			this.emit_value();
		},
		user_key: function () {
			this.emit_value();
		}
	}
};
</script>

<style lang="css" scoped></style>
