import { openDB } from "idb";
import { indexed_db_entities } from "@config";
import { app_verion } from "@config";
import { env } from "@config";

import StoreInterfaceClass from "./store-interface";

export default class {
	constructor(entity_name) {
		this.local_storage_interface = new StoreInterfaceClass();
		this.ddbb_name = "sentinel_solar_" + env;
		this.entity_name = entity_name;

		this.indexed_db = openDB(this.ddbb_name, app_verion, {
			upgrade(db) {
				for (let key in indexed_db_entities) {
					const store_entity_name = indexed_db_entities[key];

					db.createObjectStore(store_entity_name);
				}
			}
		});
	}

	async set(data, index_prop, expiration_date = null) {
		await this.clear_entity(this.entity_name);

		this.local_storage_interface.set(
			this.entity_name,
			{
				has_data: true,
				expiration_date: expiration_date,
				data: true
			},
			expiration_date
		);

		const tx = (await this.indexed_db).transaction(
			this.entity_name,
			"readwrite"
		);
		let promises = [];
		data.forEach((entity_item) => {
			promises.push(tx.store.add(entity_item, entity_item[index_prop]));
		});
		promises.push(tx.done);

		await Promise.all(promises);
	}

	async put(data, index_value) {
		await (await this.indexed_db).put(this.entity_name, data, index_value);
	}

	async get(index = null) {
		const entity_info = this.local_storage_interface.get(this.entity_name);
		if (!entity_info) return null;

		if (index) {
			const result = await (await this.indexed_db).get(this.entity_name, index);
			return result == {} ? null : result;
		} else {
			const data = await (await this.indexed_db).getAll(this.entity_name);
			return data.length > 0 ? data : null;
		}
	}

	async delete(index) {
		await (await this.indexed_db).delete(this.entity_name, index);
	}

	async clear_entity(custom_entity_name = null) {
		const entity_info = this.local_storage_interface.get(this.entity_name);
		if (!entity_info || !entity_info.has_data) return null;

		await (await this.indexed_db).clear(custom_entity_name || this.entity_name);
		this.local_storage_interface.delete_entity_values(
			custom_entity_name || this.entity_name
		);
	}

	async delete_all_entities() {
		let entity_names = [];
		for (const index in indexed_db_entities) {
			entity_names.push(indexed_db_entities[index]);
		}

		const tx = (await this.indexed_db).transaction(entity_names, "readwrite");
		let promises = [];
		for (let key in indexed_db_entities) {
			const entity_name = indexed_db_entities[key];
			promises.push(tx.objectStore(entity_name).clear());
		}

		promises.push(tx.done);
		await Promise.all(promises);
	}

	async remove_db() {
		if (
			env != "test" &&
			//Firefox does not support window.indexedDB.databases. Doc: https://developer.mozilla.org/en-US/docs/Web/API/IDBFactory/databases
			typeof window.indexedDB.databases == "function"
		) {
			const exists_database = (await window.indexedDB.databases())
				.map((db) => db.name)
				.includes(this.ddbb_name);

			if (exists_database) {
				window.indexedDB.deleteDatabase(this.ddbb_name);
			}
		}
	}
}
