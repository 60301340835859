<template>
	<div class="full-height full-width">
		<div v-if="monthly_aggregated_energy.length == 0">
			<Alert type="info" message="general.errors.no_data_available" />
		</div>
		<div v-else-if="loading" class="flex-center-content full-height">
			<Loader size="20" />
		</div>
		<div v-else class="grid-with-same-rows-height gap-s full-height">
			<div
				v-for="item in saved_data"
				class="rounded-s grey3-bg p-l-m flex-vertical-center"
			>
				<div class="display-flex gap-m">
					<div
						class="full-rounded flex-center-content grey1-bg"
						style="width: 50px; height: 50px"
					>
						<Icon
							:icon="item.icon"
							size="20"
							:style="{
								color: item.icon_color
							}"
						/>
					</div>
					<div class="display-flex-vertical-space-between">
						<div class="font-700">
							<span class="font-xxl">
								{{ item.value == null ? "-" : item.value }}
							</span>
							<span
								v-if="item.value != null"
								class="font-xl m-l-xs"
								v-html="item.unit"
							></span>
						</div>
						<div>
							{{ $t(item.multilingual_label) }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import AssetSavingsTotalFinderClass from "@src/asset-savings/application/asset-savings-total-finder";
import LocationEnergyMeasurementProvidersServiceClass from "@services/location-services/location-energy-measurement-providers-service";

const AssetSavingsTotalFinder = new AssetSavingsTotalFinderClass();
const LocationEnergyMeasurementProvidersService =
	new LocationEnergyMeasurementProvidersServiceClass();

export default {
	name: "AssetTotalSavings",
	components: {
		Alert,
		Loader
	},
	props: {
		asset: Object,
		monthly_aggregated_energy: Array
	},
	data() {
		return {
			loading: false,
			asset_total_savings: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_total_savings =
				await AssetSavingsTotalFinder.get_asset_savings_total(this.asset.id);

			this.asset_total_savings = asset_total_savings
				? asset_total_savings.total_savings
				: null;
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	computed: {
		saved_data() {
			if (
				!this.monthly_aggregated_energy ||
				!Array.isArray(this.monthly_aggregated_energy)
			)
				return {
					energy_production: null,
					energy_consumption: null,
					energy_self_consumption: null
				};

			let data = {
				energy_production: 0,
				energy_consumption: 0,
				energy_self_consumption: 0
			};

			this.monthly_aggregated_energy.forEach((item) => {
				if (item.energy_production)
					data.energy_production += item.energy_production;
				if (item.energy_consumption)
					data.energy_consumption += item.energy_consumption;
				if (item.energy_self_consumption)
					data.energy_self_consumption += item.energy_self_consumption;
			});

			const saved_data =
				LocationEnergyMeasurementProvidersService.get_saved_from_aggregated(
					data
				);

			return [
				{
					multilingual_label: "pv_main_panels.savings.total_saved",
					value: this.$is_number(this.asset_total_savings)
						? this.$format_number(
								this.asset_total_savings,
								this.asset_total_savings >= 1000 ? 0 : 2
						  )
						: null,
					unit: "€",
					icon: "euro",
					icon_color: "var(--green1)"
				},

				{
					multilingual_label: "pv_main_panels.savings.total_saved",
					value: this.$is_number(saved_data.energy_self_consumption)
						? this.$format_number(
								saved_data.energy_self_consumption,
								saved_data.energy_self_consumption >= 1000 ? 0 : 2
						  )
						: null,
					unit: "kWh",
					icon: "sun",
					icon_color: "var(--warning-color)"
				},

				{
					multilingual_label: "pv_main_panels.savings.total_avoided",
					value: this.$is_number(saved_data.co2)
						? this.$format_number(saved_data.co2, 0)
						: null,
					unit: "co<sub>2</sub> Kg",
					icon: "co2",
					icon_color: "white"
				}
			];
		}
	}
};
</script>

<style lang="css" scoped>
.saving_description {
	font-size: 16px;
}
.number {
	font-size: 22px;
}
</style>
