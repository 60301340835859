<template>
	<div class="white-bg rounded-s" style="width: 500px">
		<PageHeader
			:text="$t('asset_energy_panel.download_data')"
			:on_close_click="close_tool"
		/>
		<div class="p-sm">
			<div v-if="loading">
				<Loader size="30" />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<div v-else-if="download_percentage != null">
				<LoaderBar
					:percentage="download_percentage"
					:show_percentage="download_percentage >= 5"
				/>
			</div>
			<div v-else>
				<div v-if="download_error" class="m-b">
					<Alert :message="download_error" />
				</div>

				<div class="display-flex m-t p-b-l">
					<div class="flex-vertical-center">{{ $t("general.period") }}</div>

					<DatePicker
						class="m-l"
						v-model="from"
						:allow_from="asset_energy_data_first_date"
						:allow_to="to"
					/>

					<DatePicker
						class="m-l"
						v-model="to"
						:allow_from="from"
						:allow_to="asset_energy_data_last_date"
					/>
				</div>
				<div class="m-t flex-horizontal-right">
					<button class="btn btn-grey" v-on:click="download('xlsx')">
						{{ $t("asset_energy_panel.download_excel") }}
					</button>
					<button class="btn btn-grey m-l" v-on:click="download('csv')">
						{{ $t("asset_energy_panel.download_csv") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader.vue";
import DatePicker from "@form_components/Datepicker.vue";
import LoaderBar from "@sub_components/LoaderBar.vue";

import moment from "moment";

import PvMeasuredVariables from "@entities/variables/pv-measured-data";
import AssetDayOptimalPowerFinderClass from "@src/asset-energy-data/application/asset-day-optimal-power-finder.js";
import AssetEnergyAvailableDateFinderClass from "@src/asset-energy-data/application/asset-energy-available-date-finder.js";

const AssetEnergyAvailableDateFinder =
	new AssetEnergyAvailableDateFinderClass();
const AssetDayOptimalPowerFinder = new AssetDayOptimalPowerFinderClass();

export default {
	name: "AssetEnergyMeasurementsExport",
	components: {
		Loader,
		Alert,
		PageHeader,
		DatePicker,
		LoaderBar
	},
	data() {
		return {
			loading: true,
			error: null,
			download_error: null,
			download_percentage: null,
			asset_energy_data_first_date: null,
			asset_energy_data_last_date: null,
			from: null,
			to: null
		};
	},
	async mounted() {
		try {
			const asset_id = this.$route.params.asset_id;
			this.asset_energy_data_first_date =
				await AssetEnergyAvailableDateFinder.get_asset_energy_data_first_date(
					asset_id
				);
			this.asset_energy_data_last_date =
				await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
					asset_id
				);

			this.to = this.asset_energy_data_last_date;
			this.from = moment(this.to).subtract(7, "days").toDate();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		close_tool() {
			this.$emit("close");
		},
		async download(file_type) {
			this.download_percentage = 0;

			try {
				const asset_id = this.$route.params.asset_id;
				const days_diff = moment(this.to).diff(this.from, "days");
				const days_to_download = days_diff + 1;

				let result = [];
				let day_to_request = this.from;
				for (let index = 1; index <= days_to_download; index++) {
					const day_data =
						await AssetDayOptimalPowerFinder.get_asset_day_optimal_power(
							asset_id,
							day_to_request,
							true
						);

					if (day_data && day_data.length > 0) result = result.concat(day_data);

					day_to_request = moment(day_to_request).add(1, "days").toDate();

					this.download_percentage = (index * 100) / days_to_download;
				}

				let result_variables = ["time"];
				let energy_variables = Object.keys(result[0]).filter(
					(item) => item != "time"
				);
				result_variables = result_variables.concat(energy_variables);
				result_variables = result_variables.filter(
					(variable) =>
						variable == "time" ||
						result.find((row) => this.$is_number(row[variable]))
				);

				let result_as_array = [];
				result_as_array.push(
					result_variables.map((item) =>
						this.$t(
							item == "time" ? "dashboard.time" : PvMeasuredVariables[item].name
						)
					)
				);

				result.forEach((result_row) => {
					let row = [];

					result_variables.forEach((variable) => {
						row.push(
							variable == "time"
								? this.$format_date_to_day_hour_minute(result_row[variable])
								: this.$format_number(result_row[variable])
						);
					});

					result_as_array.push(row);
				});

				switch (file_type) {
					case "xlsx":
						this.$download_array_as_xlsx_file(result_as_array, "Export.xlsx");
						break;
					case "csv":
						this.$download_array_as_csv_file(result_as_array, "Export");
						break;
					default:
						break;
				}
			} catch (error) {
				this.download_error = error;
				setTimeout(() => {
					this.download_error = null;
				}, 8000);
			} finally {
				this.download_percentage = null;
			}
		}
	},
	watch: {
		from(new_value) {
			const to_from_days_diff = moment(this.to).diff(new_value, "days");
			if (to_from_days_diff > 31) {
				this.to = moment(new_value).add(30, "days").toDate();
			}
		},
		to(new_value) {
			const to_from_days_diff = moment(new_value).diff(this.from, "days");
			if (to_from_days_diff > 31) {
				this.from = moment(new_value).subtract(30, "days").toDate();
			}
		}
	}
};
</script>

<style scoped></style>
