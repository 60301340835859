<template>
	<div
		class="white-bg rounded-s overflow-y-scroll box-shadow1 absolute_sm full-width_xs"
	>
		<PageHeader :text="$t('general.add_new')" close_name="user-providers" />

		<div class="p-sm">
			<div>
				<span>{{ $tc("user_settings.provider", 2) }}</span>

				<div id="providers-logos-selector" class="m-t-sm">
					<div
						v-for="(
							energy_measurement_provider, index
						) in energy_measurement_providers"
						v-on:click="
							set_energy_measurement_provider(energy_measurement_provider)
						"
						class="new-providers-logo cursor-pointer text-center"
						v-bind:class="{
							selected: selected_provider == energy_measurement_provider
						}"
					>
						<img
							:src="
								get_energy_measurement_provider_logo_src(
									energy_measurement_provider
								)
							"
						/>
					</div>
					<div class="clear"></div>
				</div>
			</div>

			<div>
				<div v-if="success" class="m-b">
					<Alert message="user_settings.provider_added" type="success" />
				</div>
				<div v-else-if="error" class="m-b">
					<Alert :message="error" />
				</div>

				<div>
					<span>{{ $t("entities.energy_provider_auth.name") }}</span>
					<TextInput
						class="m-t-xs"
						v-model="new_energy_provider_auth.name"
						:placeholder="$t('general.optional')"
					/>
				</div>

				<div class="m-t">
					<FroniusLoginForm
						v-if="selected_provider == 'fronius'"
						v-model="new_energy_provider_auth.auth"
					/>
					<IngeteamLoginForm
						v-else-if="selected_provider == 'ingeteam'"
						v-model="new_energy_provider_auth.auth"
					/>
					<WibeeeLoginForm
						v-else-if="selected_provider == 'wibeee'"
						v-model="new_energy_provider_auth.auth"
					/>
					<HuaweiFusionsolarLoginForm
						v-else-if="selected_provider == 'huawei-fusionsolar'"
						v-model="new_energy_provider_auth.auth"
					/>
					<HuaweiNetecoLoginForm
						v-else-if="selected_provider == 'huawei-neteco'"
						v-model="new_energy_provider_auth.auth"
					/>
					<EnphaseLoginForm
						v-else-if="selected_provider == 'enphase'"
						v-model="new_energy_provider_auth.auth"
					/>
					<EnphaseV4LoginForm
						v-else-if="selected_provider == 'enphase-v4'"
						v-model="new_energy_provider_auth.auth"
					/>
					<DatadisLoginForm
						v-else-if="selected_provider == 'datadis'"
						v-model="new_energy_provider_auth.auth"
					/>
					<TwinmeterLoginForm
						v-else-if="selected_provider == 'twinmeter'"
						v-model="new_energy_provider_auth.auth"
					/>
					<VictronLoginForm
						v-else-if="selected_provider == 'victron'"
						v-model="new_energy_provider_auth.auth"
					/>
					<SungrowLoginForm
						v-else-if="selected_provider == 'sungrow'"
						v-model="new_energy_provider_auth.auth"
					/>
					<SolaredgeLoginForm
						v-else-if="selected_provider == 'solaredge'"
						v-model="new_energy_provider_auth.auth"
					/>
					<LacecalLoginForm v-else-if="selected_provider == 'lacecal'" />
					<SolaxLoginForm
						v-else-if="selected_provider == 'solax'"
						v-model="new_energy_provider_auth.auth"
					/>
					<SunvecxLoginForm
						v-else-if="selected_provider == 'sunvecx'"
						v-model="new_energy_provider_auth.auth"
					/>
					<GrowattLoginForm
						v-else-if="selected_provider == 'growatt'"
						v-model="new_energy_provider_auth.auth"
					/>
					<SolisLoginForm
						v-else-if="selected_provider == 'solis'"
						v-model="new_energy_provider_auth.auth"
					/>
				</div>
			</div>

			<div v-if="selected_provider != 'sma'" class="m-t">
				<Loader v-if="loading" size="30" />
				<div v-else class="btn btn-primary right" @click="save_provider">
					{{ $t("general.save") }}
				</div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import Alert from "@alert";

import TextInput from "@form_components/TextInput";
import FroniusLoginForm from "./energy-providers-login-forms/FroniusLoginForm";
import IngeteamLoginForm from "./energy-providers-login-forms/IngeteamLoginForm";
import WibeeeLoginForm from "./energy-providers-login-forms/WibeeeLoginForm";
import HuaweiFusionsolarLoginForm from "./energy-providers-login-forms/HuaweiFusionsolarLoginForm";
import HuaweiNetecoLoginForm from "./energy-providers-login-forms/HuaweiNetecoLoginForm";
import EnphaseLoginForm from "./energy-providers-login-forms/EnphaseLoginForm";
import EnphaseV4LoginForm from "./energy-providers-login-forms/EnphaseV4LoginForm";
import DatadisLoginForm from "./energy-providers-login-forms/DatadisLoginForm";
import TwinmeterLoginForm from "./energy-providers-login-forms/TwinmeterLoginForm";
import VictronLoginForm from "./energy-providers-login-forms/VictronLoginForm";
import SungrowLoginForm from "./energy-providers-login-forms/SungrowLoginForm";
import SolaredgeLoginForm from "./energy-providers-login-forms/SolaredgeLoginForm";
import LacecalLoginForm from "./energy-providers-login-forms/LacecalLoginForm.vue";
import SolaxLoginForm from "./energy-providers-login-forms/SolaxLoginForm.vue";
import SunvecxLoginForm from "./energy-providers-login-forms/SunvecxLoginForm.vue";
import GrowattLoginForm from "./energy-providers-login-forms/GrowattLoginForm.vue";
import SolisLoginForm from "./energy-providers-login-forms/SolisLoginForm.vue";

import EnergyMeasurementProviderServiceClass from "@services/energy-measurement-provider-service";
import EnergyProviderAuthCreatorClass from "@src/energy-provider-auth/application/energy-provider-auth-creator";

const EnergyMeasurementProviderService =
	new EnergyMeasurementProviderServiceClass();
const EnergyProviderAuthCreator = new EnergyProviderAuthCreatorClass();

export default {
	name: "EnergyProvidersAuthNew",
	components: {
		PageHeader,
		Loader,
		Alert,
		TextInput,
		FroniusLoginForm,
		IngeteamLoginForm,
		WibeeeLoginForm,
		HuaweiFusionsolarLoginForm,
		HuaweiNetecoLoginForm,
		EnphaseLoginForm,
		EnphaseV4LoginForm,
		DatadisLoginForm,
		TwinmeterLoginForm,
		VictronLoginForm,
		SungrowLoginForm,
		SolaredgeLoginForm,
		LacecalLoginForm,
		SolaxLoginForm,
		SunvecxLoginForm,
		GrowattLoginForm,
		SolisLoginForm
	},
	props: ["refresh"],
	data() {
		let all_providers_slugs =
			EnergyMeasurementProviderService.get_all_energy_measurement_providers_slugs();

		const disabled_providers_login = ["vmc", "custom"];
		const allowed_providers_login_slugs = all_providers_slugs.filter(
			(slug) => disabled_providers_login.indexOf(slug) == -1
		);

		return {
			success: false,
			energy_measurement_providers: allowed_providers_login_slugs,
			error: null,
			loading: undefined,
			selected_provider: "fronius",
			new_energy_provider_auth: {
				name: null,
				auth: null
			}
		};
	},
	methods: {
		get_energy_measurement_provider_logo_src(provider_slug) {
			return EnergyMeasurementProviderService.get_energy_measurement_provider_img_src(
				provider_slug
			);
		},
		set_energy_measurement_provider(provider_slug) {
			this.selected_provider = provider_slug;

			this.error = null;
			this.new_energy_provider_auth.auth = {};
		},
		async save_provider() {
			this.loading = true;
			this.error = null;

			try {
				await EnergyProviderAuthCreator.energy_provider_auth_create(
					this.selected_provider,
					this.new_energy_provider_auth
				);
				this.success = true;
				this.new_energy_provider_auth = {
					name: this.selected_provider,
					auth: null
				};

				setTimeout(() => {
					this.success = null;
				}, 5000);
			} catch (e) {
				if (e.code == 409) {
					this.error = "general.errors.invalid_credentials";
				} else {
					this.error = e;
				}
			} finally {
				this.loading = undefined;
			}
		}
	}
};
</script>

<style scoped>
#providers-logos-selector {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 15px;
	row-gap: 30px;
}
.new-providers-logo img {
	max-height: 30px;
	max-width: 100%;
	opacity: 0.5;
}
.new-providers-logo.selected img {
	opacity: 1;
}
</style>
