import EnergyProviderCreatorInterfaceClass from "../infrastructure/energy-provider-auth-creator-interface";
import EnergyProviderAuthCreatorValidators from "../domain/energy-provider-auth-creator-validators";
import EnergyProvidersStoreUtil from "./utils/energy-provider-auths-store-util";
import EnergyProviderValidator from "../domain/energy-provider-auth-finder-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new EnergyProviderCreatorInterfaceClass();
		this.store_util = EnergyProvidersStoreUtil;
		this.validator = EnergyProviderValidator;
	}

	async energy_provider_auth_create(provider, params) {
		for (let key in params.auth) {
			if (!params.auth[key]) {
				params.auth[key] = undefined;
			}
		}

		const provider_auth_validator =
			EnergyProviderAuthCreatorValidators(provider);
		if (provider_auth_validator)
			params = provider_auth_validator.validate(params);
		try {
			const created_energy_provider =
				await this.backend_interface.energy_provider_auth_create(
					provider,
					params
				);

			const validated_energy_provider = this.validator.validate(
				created_energy_provider
			);

			this.store_util.add_or_update_item(validated_energy_provider);

			return validated_energy_provider;
		} catch (error) {
			throw new AppError(
				"error_create_energy_provider",
				"general.errors.general_error",
				error
			);
		}
	}
}
