import Joi from "joi";
import Validator from "@validators/validator";

const user_energy_measurement_providers_data = Joi.object({
	pr_limit: Joi.number()
		.min(0)
		.max(100)
});

export default new Validator(user_energy_measurement_providers_data);
