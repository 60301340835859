import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";

function _convert_backend_provider_name(backend_provider_name) {
	return BackendEntityConverter.convert_backend_value_to_local(
		"energy_measurement_provider_names",
		backend_provider_name
	);
}

function _is_number(posible_number) {
	return !isNaN(posible_number);
}

export default (backend_energy_system) => {
	let result = {
		id: backend_energy_system.id,
		name: backend_energy_system.name,
		system_type: backend_energy_system.systemType,
		has_provider: backend_energy_system.hasProvider || false
	};

	const posible_props_names_convertion = {
		creationDate: "creation_date",
		coordinates: "coordinates",
		commissioningDate: "commissioning_date",
		cups: "cups"
	};

	for (let backend_prop_name in posible_props_names_convertion) {
		const local_prop_name = posible_props_names_convertion[backend_prop_name];
		if (backend_energy_system[backend_prop_name]) {
			switch (local_prop_name) {
				case "commissioning_date":
				case "creation_date":
					result[local_prop_name] = new Date(
						backend_energy_system[backend_prop_name]
					);
					break;

				default:
					result[local_prop_name] = backend_energy_system[backend_prop_name];
					break;
			}
		}
	}

	if (result.has_provider) {
		result.provider_data = {
			provider_name: _convert_backend_provider_name(
				backend_energy_system.providerData.providerName
			),
			system_id_in_provider:
				backend_energy_system.providerData.systemIdInProvider,
			energy_provider_auth_id: backend_energy_system.providerData.authId
		};
	}

	if (backend_energy_system.assets && backend_energy_system.assets.length > 0) {
		result.assets = [];

		backend_energy_system.assets.forEach((item) => {
			result.assets.push({
				id: item.id,
				rate_contributed_generation: isNaN(item.rateContributedGeneration)
					? 0
					: item.rateContributedGeneration * 100,
				rate_contributed_consumption: isNaN(item.rateContributedConsumption)
					? 0
					: item.rateContributedConsumption * 100
			});
		});
	}

	if (backend_energy_system.pvData) {
		const backend_system_pv_data = backend_energy_system.pvData;
		result.pv_data = {
			peak_power: _is_number(backend_system_pv_data.peakPower)
				? backend_system_pv_data.peakPower
				: undefined,
			nominal_power: _is_number(backend_system_pv_data.nominalPower)
				? backend_system_pv_data.nominalPower
				: undefined,
			tracking: backend_system_pv_data.tracking || undefined,
			tilt: _is_number(backend_system_pv_data.tilt)
				? backend_system_pv_data.tilt
				: undefined,
			azimuth: _is_number(backend_system_pv_data.azimuth)
				? backend_system_pv_data.azimuth
				: undefined,
			losses: _is_number(backend_system_pv_data.losses)
				? backend_system_pv_data.losses
				: undefined,
			pv_panel_models: backend_system_pv_data.pvPanelModels || undefined,
			solar_type: backend_system_pv_data.solarType,
			facility_type: backend_system_pv_data.facilityType,
			voltage: backend_system_pv_data.voltage,
			energy_to_grid_price: backend_system_pv_data.energyToGridPrice,
			distribution_company: backend_system_pv_data.distributionCompany,
			land_registry_reference: backend_system_pv_data.landRegistryReference,
			connection_type: backend_system_pv_data.connectionType,
			system_id_in_grid: backend_system_pv_data.systemIdInGrid
		};

		if (backend_system_pv_data.estimatedProduction) {
			result.pv_data.estimated_production = {
				panel_degradation:
					backend_system_pv_data.estimatedProduction.panelDegradation,
				data: backend_system_pv_data.estimatedProduction.data
			};
		}
	}

	if (backend_energy_system.consumptionData) {
		result.consumption_data = _convert_backend_system_consumption_data(
			backend_energy_system.consumptionData
		);
	}

	if (backend_energy_system.savingsSettings) {
		result.savings_settings = {
			energy_from_grid_price_rate_id:
				backend_energy_system.savingsSettings.energyFromGridPriceRateId || null
		};

		if (backend_energy_system.savingsSettings.customPrices) {
			result.savings_settings.custom_price_types =
				backend_energy_system.savingsSettings.customPrices;
		}
	}

	return result;
};

function _convert_backend_system_consumption_data(
	backend_system_consumption_data
) {
	return {
		contracted_power: backend_system_consumption_data.contractedPower,
		facility_type: backend_system_consumption_data.facilityType,
		connection_type: backend_system_consumption_data.connectionType,
		land_registry_reference:
			backend_system_consumption_data.landRegistryReference,
		distribution_company: backend_system_consumption_data.distributionCompany,
		energy_from_grid_price: backend_system_consumption_data.energyFromGridPrice
	};
}
