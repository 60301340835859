<template>
	<div class="relative">
		<Multiselect
			:label="label"
			:trackBy="label"
			:valueProp="track_by"
			:mode="multiple ? 'tags' : 'single'"
			@select="changed()"
			@deselect="changed()"
			@clear="clear()"
			v-model="selected"
			:options="formatted_options"
			:placeholder="placeholder || null"
			:close-on-select="close_on_select"
			:searchable="searchable || true"
			:show-labels="false"
			:createOption="allow_new_options || false"
			@tag="add_new_option"
			:tag-placeholder="$t('general.add_new')"
			:disabled="disabled"
		></Multiselect>

		<div
			v-if="allow_select_all && multiple != false"
			class="multiselect-select-all primary-bg white cursor-pointer"
			v-on:click="select_all"
		>
			{{ $t("general.select_all") }}
		</div>
	</div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
	name: "CustomMultiselect",
	components: {
		Multiselect
	},
	props: [
		"value",
		"multiple",
		"label",
		"track_by",
		"options",
		"placeholder",
		"close_on_select",
		"searchable",
		"allow_select_all",
		"allow_new_options",
		"disabled"
	],
	data() {
		return {
			selected: null
		};
	},
	methods: {
		changed() {
			this.emit(this.selected || null);
		},
		clear() {
			this.emit(null);
		},
		emit(value) {
			this.$emit("input", value);
		},
		set_value(value) {
			if (this.track_by) {
				this.selected = this.multiple ? [] : null;

				if (Array.isArray(value)) {
					value.forEach((item) => {
						let value = this.formatted_options.find((data) => {
							if (typeof data[this.track_by] == "object") {
								return _.isEqual(data[this.track_by], item);
							} else return data[this.track_by] == item;
						});

						if (value) this.selected.push(value[this.track_by]);
					});
				} else if (value) {
					const selected = this.formatted_options.find(
						(data) => data[this.track_by] == value
					);
					this.selected = selected ? selected[this.track_by] : null;
				}
			} else {
				this.selected = value;
			}
		},
		select_all() {
			if (this.track_by) {
				let selected_values = [];
				this.options.forEach((option) =>
					selected_values.push(option[this.track_by])
				);

				this.set_value(selected_values);
				this.emit(selected_values);
			}
		},
		add_new_option(value) {
			this.$emit("new_option_added", value);
		}
	},
	computed: {
		formatted_options() {
			//Format options
			if (Array.isArray(this.options)) return this.options;
			else {
				var formatted_options = [];
				for (let key in this.options) {
					formatted_options.push({
						[this.track_by]: this.options[key][this.track_by],
						[this.label]: this.options[key][this.label]
					});
				}
				return formatted_options;
			}
		}
	},
	mounted() {
		//Format value
		this.set_value(this.value);
	},
	watch: {
		value: {
			handler(new_value, old_value) {
				this.set_value(new_value);
			},
			deep: true
		}
	}
};
</script>

<style media="screen">
@import "@vueform/multiselect/themes/default.css";

.multiselect-wrapper {
	min-height: fit-content;
}
.multiselect-select-all {
	position: absolute;
	right: 0;
	top: -10px;
	font-size: 12px;
	padding: 1px 4px;
	border-radius: 3px;
}
.multiselect {
	min-height: 38px;
}
.multiselect-tag {
	background-color: var(--custom-primary);
}
.multiselect-caret {
	z-index: auto;
}
.multiselect-clear {
	z-index: inherit;
}
</style>
