export default {
	fronius: {
		full_name: "Fronius",
		img_src: "/img/inverters/fronius-logo.svg"
	},
	sma: {
		full_name: "SMA",
		img_src: "/img/inverters/sma-logo.svg"
	},
	enphase: {
		full_name: "Enphase",
		img_src: "/img/inverters/enphase-logo.png"
	},
	"enphase-v4": {
		full_name: "Enphase V4",
		img_src: "/img/inverters/enphase-v4-logo.png"
	},
	ingeteam: {
		full_name: "Ingeteam",
		img_src: "/img/inverters/ingeteam-logo.gif"
	},
	wibeee: {
		full_name: "Wibeee",
		img_src: "/img/inverters/wibeee-logo.png"
	},
	"huawei-fusionsolar": {
		full_name: "Huawei - Fusionsolar",
		img_src: "/img/inverters/huawei-fusionsolar-logo.svg"
	},
	"huawei-neteco": {
		full_name: "Huawei - Neteco",
		img_src: "/img/inverters/huawei-neteco-logo.png"
	},
	datadis: {
		full_name: "Datadis",
		img_src: "/img/inverters/logo-datadis.png"
	},
	twinmeter: {
		full_name: "Twinmeter",
		img_src: "/img/inverters/twinmeter-logo.png"
	},
	victron: {
		full_name: "Victron",
		img_src: "/img/inverters/victron-logo.png"
	},
	sungrow: {
		full_name: "Sungrow",
		img_src: "/img/inverters/sungrow-logo.png"
	},
	vmc: {
		full_name: "VMC",
		img_src: "/img/inverters/vmc-logo.jpeg"
	},
	solaredge: {
		full_name: "Solaredge",
		img_src: "/img/inverters/solaredge-logo.png"
	},
	lacecal: {
		full_name: "Lacecal",
		img_src: "/img/inverters/lacecal-logo.png"
	},
	solax: {
		full_name: "Solax",
		img_src: "/img/inverters/solax-logo.png"
	},
	sunvecx: {
		full_name: "SunvecX",
		img_src: "/img/inverters/sunvecx-logo.png"
	},
	growatt: {
		full_name: "Growatt",
		img_src: "/img/inverters/growatt-logo.png"
	},
	custom: {
		full_name: null,
		img_src: null
	},
	solis: {
		full_name: "Solis",
		img_src: "/img/inverters/solis-logo.png"
	}
};
