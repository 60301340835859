import Validator from "@validators/validator";
const Joi = require("joi");

const ticket_schema = Joi.object({
	id: Joi.string(),
	title: Joi.string().required(),
	asset_id: Joi.string().required(),
	assigned_to: Joi.string(),
	status: Joi.string(),
	created_by: Joi.string(),
	creation: Joi.date(),
	description: Joi.string(),
	faulty_equipments: Joi.array(),
	visit: Joi.object({
		date: Joi.date(),
		hours: Joi.number(),
		distance: Joi.number(),
		operators: Joi.number(),
		materials: Joi.string(),
		paid_by: Joi.string(),
		description: Joi.string(),
		total_cost: Joi.number()
	})
});

export default new Validator(ticket_schema, "ticketing.ticket_entity.");
