import ValidationError from "@classes/errors/validation_error";
const _ = require("lodash");

export const ValidatorErrorCodes = {
	field_required: "validation_errors.messages.field_required",
	field_not_valid: "validation_errors.messages.field_not_valid",
	field_must_be_date: "validation_errors.messages.field_must_be_date",
	field_must_be_date_less: "validation_errors.messages.field_must_be_date_less",
	field_must_be_a_string: "validation_errors.messages.field_must_be_a_string",
	string_must_be_url: "validation_errors.messages.string_must_be_url",
	string_must_be_email: "validation_errors.messages.string_must_be_email",
	field_must_be_number: "validation_errors.messages.field_must_be_number",
	number_must_be_integer: "validation_errors.messages.number_must_be_integer",
	field_must_be_higher: "validation_errors.messages.field_must_be_higher",
	field_must_be_lower: "validation_errors.messages.field_must_be_lower",
	field_must_be_boolean: "validation_errors.messages.field_must_be_boolean",
	field_must_be_object: "validation_errors.messages.field_must_be_object",
	field_must_be_array: "validation_errors.messages.field_must_be_array",
	array_min_elements: "validation_errors.messages.array_min_elements"
};

export default class Validator {
	constructor(schema, fields_multilinual_prefix = null) {
		this.schema = schema.messages({
			"any.required": ValidatorErrorCodes.field_required,
			"any.only": ValidatorErrorCodes.field_not_valid,
			"any.invalid": ValidatorErrorCodes.field_not_valid,
			"date.base": ValidatorErrorCodes.field_must_be_date,
			"date.less": ValidatorErrorCodes.field_must_be_date_less,
			"string.base": ValidatorErrorCodes.field_must_be_a_string,
			"string.uri": ValidatorErrorCodes.string_must_be_url,
			"string.email": ValidatorErrorCodes.string_must_be_email,
			"string.pattern.base": ValidatorErrorCodes.field_not_valid,
			"number.base": ValidatorErrorCodes.field_must_be_number,
			"number.integer": ValidatorErrorCodes.number_must_be_integer,
			"number.min": ValidatorErrorCodes.field_must_be_higher,
			"number.max": ValidatorErrorCodes.field_must_be_lower,
			"boolean.base": ValidatorErrorCodes.field_must_be_boolean,
			"object.base": ValidatorErrorCodes.field_must_be_object,
			"array.base": ValidatorErrorCodes.field_must_be_array,
			"array.min": ValidatorErrorCodes.array_min_elements
		});
		this.fields_multilinual_prefix = fields_multilinual_prefix;
	}

	_get_schema_properties() {
		const schema_map = this.schema._ids._byKey;
		const schema_keys = Array.from(schema_map.keys());
		return schema_keys;
	}

	_delete_not_in_schema_properties(object) {
		if (Array.isArray(object)) return object;

		const schema_properties = this._get_schema_properties();

		for (let key in object) {
			if (schema_properties.indexOf(key) == -1) delete object[key];
		}

		return object;
	}

	validate(data) {
		let data_with_shema_properties =
			this._delete_not_in_schema_properties(data);
		data_with_shema_properties = this._delete_empty_properties(
			data_with_shema_properties
		);

		const result = this.schema.validate(data_with_shema_properties);

		if (
			!result.error ||
			//Allow required properties to be null
			result.error.details.find(
				(item) => item.type != "any.required" && item.context.value == null
			)
		) {
			return data_with_shema_properties;
		}

		var field_limit;
		if (
			result.error.details[0].context.hasOwnProperty("limit") &&
			result.error.details[0].context.limit != null
		) {
			const validated_object = result.error._original;
			const limit = result.error.details[0].context.limit;

			if (typeof limit && limit.type == "value") {
				field_limit = validated_object[limit.key];
			} else {
				field_limit = limit;
			}
		} else {
			field_limit = undefined;
		}

		const error_path_as_array = result.error.details[0].path;
		const error_path_last_property =
			error_path_as_array[error_path_as_array.length - 1];
		const error_path_penultimate_property =
			error_path_as_array[error_path_as_array.length - 2];

		const property_name =
			!isNaN(result.error.details[0].context.key) &&
			error_path_as_array.length > 1
				? error_path_penultimate_property
				: result.error.details[0].context.key;
		const wrong_value_property_path =
			result.error.details[0].context.label || undefined;
		const received_incorrect_value = _.get(
			result.value,
			wrong_value_property_path
		);

		throw new ValidationError(
			property_name,
			result.error.details[0].message,
			data,
			{
				field_limit: field_limit,
				property_path: wrong_value_property_path,
				received_value: received_incorrect_value || undefined,
				valid_values: result.error.details[0].context.valids || undefined,
				multilingual_field_path: this.fields_multilinual_prefix
					? this.fields_multilinual_prefix + wrong_value_property_path
					: undefined
			}
		);
	}

	_delete_empty_properties(object) {
		const replace_empty_strings_with_null = (object) => {
			for (let key in object) {
				if (
					object[key] != null &&
					((typeof object[key] === "string" && object[key] === "") ||
						(typeof object[key] === "object" &&
							!Array.isArray(object[key]) &&
							!(object[key] instanceof Date) &&
							Object.keys(object[key]).length == 0))
				)
					object[key] = null;

				/*if (object[key] === "" || object[key] === null) delete object[key];
					else if (
						object[key] !== undefined &&
						object[key].constructor === Object &&
						typeof object[key] === "object"
					) {
						if (Object.keys(object[key]).length == 0) delete object[key];
						else object[key] = replace_empty_strings_with_null(object[key]);
					}*/
			}
			return object;
		};

		return replace_empty_strings_with_null(object);
	}
}
