import EnergyMeasurementProviders from "@entities/energy-measurement-providers";
import BackendEnergyMeasurementProvidersInterfaceClass from "@interfaces/backend-interfaces/backend-locations-energy-measurements-interface";
import EnergyMeasurementProvidersUserDataValidator from "@validators/user-validators/user-energy-measurement-providers-data-validator";

export default class {
	constructor() {
		this.backend_energy_measurement_providers_interface =
			new BackendEnergyMeasurementProvidersInterfaceClass();
		this.energy_measurement_providers_data_validator =
			EnergyMeasurementProvidersUserDataValidator;
	}

	get_all_energy_measurement_providers_slugs() {
		return Object.keys(EnergyMeasurementProviders);
	}

	get_energy_measurement_provider_full_name(provider_slug) {
		return EnergyMeasurementProviders[provider_slug].full_name;
	}

	get_energy_measurement_provider_img_src(provider_slug) {
		return EnergyMeasurementProviders[provider_slug].img_src;
	}
}
