import Joi from "joi";
import Validator from "@validators/validator";

import CoordinatesValidator from "@src/shared/domain/coordinates-validator";
import EnergyDataProviderSlugValidator from "@src/energy-provider-auth/domain/energy-data-provider-slug-validator";
import EnergySystemPvDataValidator from "@src/energy-system/domain/energy-system-pv-data-validator";
import EnergySystemConsumptionDataValidator from "@src/energy-system/domain/energy-system-consumption-data-validator";
import EnergySystemSavingsSetingsValidator from "@src/energy-system/domain/energy-system-savings-settings-validator";

const energy_system_schema = Joi.object({
	id: Joi.string().required(),
	name: Joi.string().required(),
	creation_date: Joi.date(),
	coordinates: CoordinatesValidator.schema,
	commissioning_date: Joi.date(),
	system_type: Joi.string()
		.valid(
			"only_generation",
			"only_consumption",
			"generation_and_consumption",
			"only_grid_interaction"
		)
		.required(),
	cups: Joi.string(),
	has_provider: Joi.boolean().required(),
	provider_data: Joi.object({
		provider_name: EnergyDataProviderSlugValidator.schema,
		system_id_in_provider: Joi.string(),
		energy_provider_auth_id: Joi.string()
	}),
	assets: Joi.array().items(
		Joi.object({
			id: Joi.string().required(),
			rate_contributed_generation: Joi.number().min(0).max(100),
			rate_contributed_consumption: Joi.number().min(0).max(100)
		})
	),
	pv_data: EnergySystemPvDataValidator.schema,
	consumption_data: EnergySystemConsumptionDataValidator.schema,
	savings_settings: EnergySystemSavingsSetingsValidator.schema
});
export default new Validator(energy_system_schema, "entities.energy_system.");
