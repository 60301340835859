<template>
	<div
		:style="custom_checkbox_syles"
		class="grey1-bg relative cursor-pointer"
		v-bind:class="{ 'primary-bg': value }"
		v-on:click="toogle"
	>
		<div
			:style="circle_styles"
			class="absolute full-rounded white-bg animation"
			v-bind:class="{ active: value }"
		></div>
	</div>
</template>
<script>
export default {
	name: "CustomCheckbox",
	props: { value: Boolean, size: String },
	methods: {
		toogle() {
			this.$emit("input", !this.value);
		}
	},
	computed: {
		custom_checkbox_syles() {
			const normal_size_styles = {
				width: "50px",
				height: "25px",
				borderRadius: "15px"
			};
			const small_size_styles = {
				width: "30px",
				height: "17px",
				borderRadius: "15px"
			};

			return this.size == "small" ? small_size_styles : normal_size_styles;
		},
		circle_styles() {
			const size_in_px = this.size == "small" ? "15" : "21";
			const margin_in_px = this.size == "small" ? "1" : "2";

			const styles = {
				width: size_in_px + "px",
				height: size_in_px + "px",
				top: margin_in_px + "px",
				left: margin_in_px + "px"
			};

			if (this.value) {
				styles.right = margin_in_px + "px";
				styles.left = "initial";
			}

			return styles;
		}
	}
};
</script>
