<template>
	<div
		v-if="status && id"
		class="ticket-status text-center rounded-m p-h-xs margin-center white full-width"
		v-bind:style="{
			backgroundColor: status[id].color
		}"
	>
		{{ status[id].name }}
	</div>
</template>

<script>
import TicketServiceClass from "@services/user-services/ticket-service";

const TicketService = new TicketServiceClass();

export default {
	name: "TicketStatus",
	props: ["id"],
	data() {
		return {
			status: null
		};
	},
	async created() {
		this.status = await TicketService.get_ticketing_status();
	}
};
</script>

<style>
.ticket-status {
	line-height: 14px;
}
</style>
