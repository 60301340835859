import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.object({
	pr_limit: Joi.object({
		pr_lowest_limit: Joi.number().min(0).max(100)
	})
});

export default new Validator(schema);
