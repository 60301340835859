import BackendInterface from "@src/shared/infrastructure/backend-interface";

import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_energy_system_pv_data(energy_system_id, energy_system_pv_data) {
		let formatted_data = this._convert_local_pv_data_to_backend(
			energy_system_pv_data
		);

		const url = "/api/energy-data/systems/" + energy_system_id + "/pv-data";
		const backend_updated_data = await this.put(url, {
			body_params: formatted_data
		});

		const local_updated_energy_system =
			BackendEnergySystemConverter(backend_updated_data);

		return local_updated_energy_system;
	}

	_convert_local_pv_data_to_backend(local_pv_data) {
		let backend_pv_data = {
			peakPower: local_pv_data.peak_power,
			nominalPower: local_pv_data.nominal_power,
			tracking: local_pv_data.tracking,
			tilt: local_pv_data.tilt,
			azimuth: local_pv_data.azimuth,
			losses: local_pv_data.losses,
			solarType: local_pv_data.solar_type,
			facilityType: local_pv_data.facility_type,
			voltage: local_pv_data.voltage,
			energyToGridPrice: local_pv_data.energy_to_grid_price,
			distributionCompany: local_pv_data.distribution_company,
			landRegistryReference: local_pv_data.land_registry_reference,
			connectionType: local_pv_data.connection_type,
			systemIdInGrid: local_pv_data.system_id_in_grid
		};

		if (local_pv_data.estimated_production) {
			backend_pv_data.estimatedProduction = {
				panelDegradation: local_pv_data.estimated_production.panel_degradation,
				data: local_pv_data.estimated_production.data
			};
		}

		if (local_pv_data.pv_panel_models) {
			backend_pv_data.pvPanelModels = local_pv_data.pv_panel_models;
		}

		return backend_pv_data;
	}
}
