import EnergyMeasurementProviderServiceClass from "@services/energy-measurement-provider-service";

const EnergyMeasurementProviderService =
	new EnergyMeasurementProviderServiceClass();

//VueJS plugins doc: https://vuejs.org/v2/guide/plugins.html
export default {
	install: function (app, options) {
		app.config.globalProperties.$get_energy_provider_img_src = function (
			provider_name
		) {
			return provider_name
				? EnergyMeasurementProviderService.get_energy_measurement_provider_img_src(
						provider_name
				  )
				: null;
		};

		app.config.globalProperties.$get_energy_provider_full_name = function (
			provider_name
		) {
			return provider_name
				? EnergyMeasurementProviderService.get_energy_measurement_provider_full_name(
						provider_name
				  )
				: null;
		};
	}
};
