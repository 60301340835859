import Joi from "joi";
import Validator from "@validators/validator";

const energy_system_schema = Joi.object({
	energy_measurements_download: Joi.object({
		optimize_all: Joi.boolean()
	})
});
export default new Validator(
	energy_system_schema,
	"entities.energy_system_custom_config."
);
