export default (provider, data) => {
	var result = {
		name: data.name || null
	};

	switch (provider) {
		case "fronius":
			result.auth = {
				userId: data.auth.user_id,
				userKey: data.auth.user_key
			};
			break;
		case "ingeteam":
			result.auth = data.auth;
			break;
		case "wibeee":
			result.auth = data.auth;
			break;
		case "huawei-fusionsolar":
			result.auth = {
				username: data.auth.username,
				systemCode: data.auth.system_code,
				region: data.auth.region
			};
			break;
		case "huawei-neteco":
			result.auth = {
				username: data.auth.username,
				password: data.auth.password
			};
			break;
		case "enphase":
			result.auth = {
				userId: data.auth.user_id,
				userKey: data.auth.user_key
			};
			break;
		case "enphase-v4":
			result.auth = {
				planType: data.auth.plan_type,
				clientId: data.auth.client_id,
				clientSecret: data.auth.client_secret,
				apiKey: data.auth.api_key
			};

			switch (data.auth.plan_type) {
				case "developer":
					result.auth.userCode = data.auth.user_code;
					result.auth.redirectUri = data.auth.redirect_uri;
					break;
				case "partner":
					result.auth.username = data.auth.username;
					result.auth.password = data.auth.password;
					break;
				default:
					break;
			}
			break;
		case "datadis":
			result.auth = {
				password: data.auth.password,
				username: data.auth.username,
				authorizedNifs: data.auth.authorized_nifs || []
			};
			break;
		case "twinmeter":
			result.auth = {
				password: data.auth.password,
				username: data.auth.username
			};
			break;
		case "victron":
			result.auth = {
				username: data.auth.username,
				password: data.auth.password
			};
			break;
		case "sungrow":
			result.auth = {
				username: data.auth.username,
				password: data.auth.password,
				appKey: data.auth.app_key,
				xAccessKey: data.auth.x_access_key
			};
			break;
		case "solaredge":
			result.auth = {
				apiKey: data.auth.api_key
			};
			break;
		case "lacecal":
			result.auth = {};
			break;
		case "solax":
			result.auth = {
				token: data.auth.token
			};
			break;
		case "sunvecx":
			result.auth = {
				token: data.auth.token
			};
			break;
		case "growatt":
			result.auth = {
				token: data.auth.token
			};
			break;
		case "solis":
			result.auth = {
				keyId: data.auth.key_id,
				keySecret: data.auth.key_secret
			};
			break;
	}
	return result;
};
