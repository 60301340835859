<template>
	<div
		style="width: 600px; height: calc(100% - 30px)"
		class="flex-auto-full-height-content rounded-s overflow-hidden grey3-bg"
	>
		<div class="header">
			<PageHeader :text="energy_system.name" :on_close_click="close" />

			<div class="p-sm">
				<div class="white-bg display-flex p-xs rounded-s box-shadow">
					<div
						v-for="(item, index) in options"
						v-bind:class="{
							'primary-color': item.key == selected_option,
							'm-l': index > 0
						}"
						v-on:click="selected_option = item.key"
						class="cursor-pointer"
					>
						{{ item.label }}
					</div>
				</div>
			</div>
		</div>
		<div class="content p-l p-b p-r">
			<div class="white-bg rounded-s box-shadow">
				<EnergySystemCustomConfig
					v-if="selected_option == 'edit'"
					:energy_system="energy_system"
				/>
				<EnergySystemMeasurementDownloadRequest
					v-if="selected_option == 'download_request'"
					:energy_system="energy_system"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader.vue";
import EnergySystemCustomConfig from "./EnergySystemCustomConfig.vue";
import EnergySystemMeasurementDownloadRequest from "./EnergySystemMeasurementDownloadRequest.vue";

export default {
	name: "AdminEditEnergySystem",
	components: {
		PageHeader,
		EnergySystemCustomConfig,
		EnergySystemMeasurementDownloadRequest
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			selected_option: "edit"
		};
	},
	methods: {
		close() {
			this.$emit("close");
		}
	},
	computed: {
		options() {
			return [
				{
					key: "edit",
					label: this.$t("general.edit")
				},
				{
					key: "download_request",
					label: "Download request"
				}
			];
		}
	}
};
</script>
