<template>
	<div class="full-width full-height absolute p-xxs rounded-xs">
		<div class="flex-auto-full-height-content full-width full-height">
			<PageHeader
				class="header"
				:text="$t('ticketing.ticketing')"
				:close_name="$route.query.location_id ? 'location-options' : 'app'"
				:close_params="
					$route.query.location_id
						? { location_id: $route.query.location_id }
						: null
				"
			/>
			<div class="content relative">
				<div
					v-if="this.$route.name != 'ticketing'"
					id="sub-page"
					class="flex-center-content"
				>
					<router-view v-on:refresh_tickets="refresh"></router-view>
				</div>

				<div class="p-sm gray-bg full-width full-height">
					<div class="flex-auto-full-height-content full-height full-width">
						<div class="header p-sm white-bg rounded-xs">
							<div class="white-bg rounded-xs">
								<div class="primary-color font-bold">
									<span class="text-uppercase">{{
										$t("ticketing.ticketing")
									}}</span>
								</div>

								<div id="header-options" class="m-t">
									<div class="display-flex">
										<div class="ticketing-filter p-r">
											<div class="font-s">
												{{ $tc("location.location", 2) }}
											</div>
											<div>
												<LocationSelect
													class="m-t-sm"
													:multiple="true"
													:track_by_id="true"
													v-model="filter.locations"
												/>
											</div>
										</div>

										<div class="ticketing-filter p-l border-l">
											<div class="font-s">
												{{ $t("ticketing.ticket_entity.created_by") }}
											</div>
											<div class="m-t-sm">
												<Multiselect
													v-if="allowed_assigned_to"
													track_by="id"
													label="first_name"
													:multiple="true"
													v-model="filter.created_by"
													:options="allowed_assigned_to"
													:close_on_select="false"
												/>
											</div>
										</div>

										<div class="ticketing-filter m-l">
											<div class="font-s">
												{{ $t("ticketing.ticket_entity.assigned_to") }}
											</div>
											<div class="m-t-sm">
												<Multiselect
													v-if="allowed_assigned_to"
													track_by="id"
													label="first_name"
													:multiple="true"
													v-model="filter.assigned_to"
													:options="allowed_assigned_to"
													:close_on_select="false"
												/>
											</div>
										</div>

										<div class="ticketing-filter m-l">
											<div class="font-s">
												{{ $t("general.status") }}
											</div>
											<div class="m-t-sm">
												<Multiselect
													v-if="status"
													track_by="id"
													label="name"
													:multiple="true"
													v-model="filter.status"
													:options="status"
													:close_on_select="false"
												/>
											</div>
										</div>

										<div v-if="equipments" class="ticketing-filter m-l">
											<div class="font-s">
												{{ $t("ticketing.ticket_entity.faulty_equipments") }}
											</div>
											<div class="m-t-sm">
												<Multiselect
													v-if="status"
													track_by="id"
													label="name"
													:multiple="true"
													v-model="filter.equipments"
													:options="equipments"
													:close_on_select="false"
												/>
											</div>
										</div>

										<div class="m-l">
											<div class="btn btn-primary m-t-lg" v-on:click="refresh">
												<Icon icon="search" />
											</div>
										</div>
									</div>

									<div class="display-flex">
										<div class="m-l">
											<router-link
												:to="{
													name: 'new-ticket'
												}"
											>
												<div
													v-html="$t('ticketing.add_new_ticket')"
													class="btn btn-primary m-t-xs p-l p-r"
												></div>
											</router-link>
										</div>

										<div v-if="location" class="p-l">
											<div class="left">{{ $t("general.shortcuts") }}</div>
											<AssetShortcuts
												class="left clear m-t-sm"
												:location="location"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="content white-bg rounded-xs m-t overflow-hidden">
							<div class="full-width full-height relative">
								<TicketList
									:refresh="refresh_tickets"
									:filter_params="filter"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import AssetShortcuts from "@sub_components/location-components/AssetShortcuts";
import TicketList from "./TicketList";
import LocationSelect from "@form_components/LocationSelect2";
import Multiselect from "@form_components/Multiselect";

import TicketServiceClass from "@services/user-services/ticket-service";
import AssetsFinderClass from "@src/asset/application/assets-finder";

const TicketService = new TicketServiceClass();
const AssetsFinder = new AssetsFinderClass();

export default {
	name: "Ticketing",
	components: {
		PageHeader,
		AssetShortcuts,
		TicketList,
		LocationSelect,
		Multiselect
	},
	data() {
		return {
			status: null,
			allowed_assigned_to: null,
			refresh_tickets: 0,
			equipments: null,
			filter: {
				locations: this.$route.query.location_id
					? [this.$route.query.location_id]
					: []
			},
			location: null
		};
	},
	async created() {
		this.load_allowed_assigned_to();
		this.status = await TicketService.get_ticketing_status();

		const location_id = this.$route.query.location_id;
		if (location_id) {
			this.location = await AssetsFinder.get_asset_by_id(location_id);
		}
	},
	methods: {
		go_to_tickets() {
			this.$router.push({ name: "ticketing" });
		},
		refresh() {
			this.refresh_tickets++;
		},
		async set_filter_equipments() {
			this.equipments = await TicketService.get_ticketing_faulty_equipments();
		},
		async load_allowed_assigned_to() {
			const result = await TicketService.get_allowed_assigned_to();
			this.allowed_assigned_to = result;
		}
	},
	mounted() {
		this.set_filter_equipments();
	}
};
</script>

<style lang="css" scoped>
#sub-page {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 3;
}
#header-options {
	display: flex;
	justify-content: space-between;
}
.ticketing-filter {
	max-width: 20%;
	min-width: 230px;
}
</style>
