import BackendInterface from "@src/shared/infrastructure/backend-interface";

import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_energy_systems(asset_id) {
		const url = "/api/asset/" + asset_id + "/energy-data/systems";
		const backend_energy_systems = await this.get(url);

		let local_energy_systems = [];
		backend_energy_systems.forEach((item) => {
			local_energy_systems.push(BackendEnergySystemConverter(item));
		});

		return local_energy_systems;
	}
}
