<template>
	<div class="p-sm full-width full-height">
		<div class="flex-auto-full-height-content">
			<div class="header">
				<span class="font-700 font-xxl">
					{{ $t("asset_energy_panel.energy_summary") }}
				</span>
			</div>
			<div class="content">
				<div v-if="loading" class="full-height flex-center-content">
					<Loader size="30" />
				</div>
				<div
					v-else-if="
						current_day_energy_data || month_energy_data || year_energy_data
					"
					id="table"
					class="full-height"
				>
					<div></div>
					<div class="flex-center-content">
						<Icon size="50" icon="solar-panel" />
					</div>
					<div class="flex-center-content">
						<Icon size="50" icon="lightbulb" />
					</div>
					<div class="flex-center-content">
						<Icon size="50" icon="grid-tower-arrow-up" />
					</div>
					<div class="flex-center-content">
						<Icon size="50" icon="grid-tower-arrow-down" />
					</div>
					<div class="flex-center-content">
						<Icon size="50" icon="sun" />
					</div>

					<div></div>
					<div class="text-center font-bold font-xl">
						{{ $t("asset_energy_panel.energy_production") }}
					</div>
					<div class="text-center font-bold font-xl">
						{{ $t("asset_energy_panel.energy_consumption") }}
					</div>
					<div class="text-center font-bold font-xl">
						{{ $t("asset_energy_panel.energy_to_grid") }}
					</div>
					<div class="text-center font-bold font-xl">
						{{ $t("asset_energy_panel.energy_from_grid") }}
					</div>
					<div class="text-center font-bold font-xl">
						{{ $t("asset_energy_panel.energy_self_consumption") }}
					</div>

					<div class="text-right font-bold font-xl p-r flex-vertical-center">
						<div class="text-right full-width">
							{{ $t("asset_energy_panel.day") }}
						</div>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{
								format_energy_data(current_day_energy_data, "energy_production")
							}}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{
								format_energy_data(
									current_day_energy_data,
									"energy_consumption"
								)
							}}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{
								format_energy_data(current_day_energy_data, "energy_to_grid")
							}}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{
								format_energy_data(current_day_energy_data, "energy_from_grid")
							}}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{
								format_energy_data(
									current_day_energy_data,
									"energy_self_consumption"
								)
							}}
						</span>
					</div>

					<div class="text-right font-bold font-xl p-r flex-vertical-center">
						<div class="text-right full-width">
							{{ $t("asset_energy_panel.month") }}
						</div>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(month_energy_data, "energy_production") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(month_energy_data, "energy_consumption") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(month_energy_data, "energy_to_grid") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(month_energy_data, "energy_from_grid") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{
								format_energy_data(month_energy_data, "energy_self_consumption")
							}}
						</span>
					</div>

					<div class="text-right font-bold font-xl p-r flex-vertical-center">
						<div class="text-right full-width">
							{{ $t("asset_energy_panel.year") }}
						</div>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(year_energy_data, "energy_production") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(year_energy_data, "energy_consumption") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(year_energy_data, "energy_to_grid") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{ format_energy_data(year_energy_data, "energy_from_grid") }}
						</span>
					</div>
					<div class="energy-value flex-center-content rounded-s">
						<span>
							{{
								format_energy_data(year_energy_data, "energy_self_consumption")
							}}
						</span>
					</div>
				</div>
				<div v-else>
					<Alert type="info" message="general.errors.no_data_available" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import moment from "moment";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder.js";
import AssetDailyAggregatedEnergyFinderClass from "@src/asset-energy-data/application/asset-daily-aggregated-energy-finder.js";
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();
const AssetDailyAggregatedEnergyFinder =
	new AssetDailyAggregatedEnergyFinderClass();

export default {
	name: "AssetEnergySummary",
	props: {
		asset_id: String
	},
	components: {
		Loader,
		Alert
	},
	data() {
		return {
			loading: false,
			current_day_energy_data: null,
			month_energy_data: null,
			year_energy_data: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			await this.set_current_month_and_year_data();
			await this.set_current_day_data();
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async set_current_month_and_year_data() {
			try {
				const asset_current_year_monthly_energy =
					await AssetMonthlyEnergyFinder.get_asset_monthly_energy_by_date(
						this.asset_id,
						moment().startOf("year").toDate(),
						moment().endOf("year").toDate()
					);

				this.year_energy_data =
					asset_current_year_monthly_energy &&
					asset_current_year_monthly_energy.length > 0
						? asset_current_year_monthly_energy.reduce((a, n) => {
								return {
									energy_production: a.energy_production + n.energy_production,
									energy_consumption:
										a.energy_consumption + n.energy_consumption,
									energy_from_grid: a.energy_from_grid + n.energy_from_grid,
									energy_to_grid: a.energy_to_grid + n.energy_to_grid,
									energy_self_consumption:
										a.energy_self_consumption + n.energy_self_consumption
								};
						  })
						: null;

				const asset_current_month_monthly_energy =
					await AssetMonthlyEnergyFinder.get_asset_monthly_energy_by_date(
						this.asset_id,
						moment().startOf("month").toDate(),
						moment().startOf("month").toDate()
					);
				this.month_energy_data = asset_current_month_monthly_energy.reduce(
					(a, n) => {
						return {
							energy_production: a.energy_production + n.energy_production,
							energy_consumption: a.energy_consumption + n.energy_consumption,
							energy_from_grid: a.energy_from_grid + n.energy_from_grid,
							energy_to_grid: a.energy_to_grid + n.energy_to_grid,
							energy_self_consumption:
								a.energy_self_consumption + n.energy_self_consumption
						};
					}
				);

				setTimeout(() => {
					this.set_current_month_and_year_data();
				}, 43200000);
			} catch (error) {
				setTimeout(() => {
					this.set_current_month_and_year_data();
				}, 300000);
			}
		},
		async set_current_day_data() {
			try {
				this.current_day_energy_data =
					await AssetDailyAggregatedEnergyFinder.get_asset_daily_aggregated_energy(
						this.asset_id,
						new Date(),
						new Date()
					);

				if (Object.keys(this.current_day_energy_data).length == 0) {
					this.current_day_energy_data = null;
					setTimeout(() => {
						this.set_current_day_data();
					}, 300000);
				} else {
					setTimeout(() => {
						this.set_current_day_data();
					}, 7200000);
				}
			} catch (error) {
				setTimeout(() => {
					this.set_current_day_data();
				}, 300000);
			}
		},
		format_energy_data(energy_data, prop_name) {
			return energy_data &&
				energy_data.hasOwnProperty(prop_name) &&
				energy_data[prop_name]
				? this.$format_number(energy_data[prop_name], 0) + " kWh"
				: "-";
		}
	}
};
</script>

<style lang="css" scoped>
#table {
	display: grid;
	grid-template-columns: min-content repeat(5, 1fr);
	column-gap: 5px;
	grid-template-rows: auto min-content repeat(3, auto);
	row-gap: 5px;
}
.energy-value {
	background-color: #f6f6fa;
	font-size: 18px;
}
</style>
