import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import IndexedDbInterfaceClass from "@src/shared/infrastructure/indexed-db-interface";

export default class {
	constructor(entity_name, store_items_limit = null, index_prop = "id") {
		this.indexed_db_interface = store_items_limit
			? new IndexedDbInterfaceClass(entity_name)
			: null;

		this.store_interface = new StoreInterfaceClass();
		this.store_entity_name = entity_name;
		this.used_cache_type_entity_name = entity_name + "_cache_type";
		this.index_prop = index_prop;

		this.store_items_limit = store_items_limit;
	}

	set_data(data, expiration_date) {
		this._set_used_store_type("store");
		this.store_interface.set(this.store_entity_name, data, expiration_date);
	}

	get_data() {
		const stored_type = this._get_used_store_type();
		if (!stored_type) return null;

		return this.store_interface.get(this.store_entity_name);
	}

	async save_items(data, expiration_date) {
		if (this.store_items_limit && data.length >= this.store_items_limit) {
			this._set_used_store_type("indexed_db");
			await this.indexed_db_interface.set(
				data,
				this.index_prop,
				expiration_date
			);
		} else {
			this._set_used_store_type("store");
			this.store_interface.set(this.store_entity_name, data, expiration_date);
		}
	}

	async get_items() {
		const stored_type = this._get_used_store_type();
		if (!stored_type) return null;

		var result;
		switch (stored_type) {
			case "store":
				result = this.store_interface.get(this.store_entity_name);
				break;
			case "indexed_db":
				result = await this.indexed_db_interface.get();
				break;
		}
		return result;
	}

	async get_cached_item_by_id(item_id) {
		const stored_type = this._get_used_store_type();
		if (!stored_type) return null;

		var result;
		switch (stored_type) {
			case "store":
				result = this.store_interface.find_by_property(
					this.store_entity_name,
					this.index_prop,
					item_id
				);
				break;
			case "indexed_db":
				result = await this.indexed_db_interface.get(item_id);
				break;
		}

		return result;
	}

	async add_or_update_item(item) {
		let stored_type = this._get_used_store_type();
		if (!stored_type) {
			this._set_used_store_type("store");
			stored_type = "store";
		}

		switch (stored_type) {
			case "store":
				this.store_interface.update_or_create_by_property(
					this.store_entity_name,
					this.index_prop,
					item.id,
					item
				);
				break;
			case "indexed_db":
				await this.indexed_db_interface.put(item, item[this.index_prop]);
				break;
		}
	}

	async update_item(item) {
		await this.add_item_to_cache(item);
	}

	async remove_item(item_id) {
		const stored_type = this._get_used_store_type();
		if (!stored_type) return null;

		switch (stored_type) {
			case "store":
				this.store_interface.delete_by_property(
					this.store_entity_name,
					this.index_prop,
					item_id
				);
				break;
			case "indexed_db":
				await this.indexed_db_interface.delete(item_id);
				break;
		}
	}

	async remove_all_items() {
		const stored_type = this._get_used_store_type();
		if (!stored_type) return null;

		switch (stored_type) {
			case "store":
				this.store_interface.delete_entity_values(this.store_entity_name);
				break;
			case "indexed_db":
				await this.indexed_db_interface.clear_entity();
				break;
		}
	}

	_set_used_store_type(store_type) {
		this.store_interface.set(this.used_cache_type_entity_name, store_type);
	}

	_get_used_store_type() {
		return this.store_interface.get(this.used_cache_type_entity_name);
	}
}
