import VuexInterface from "@interfaces/lib/vuex-interface";
import moment from "moment";

export default class {
	constructor() {
		this._set_store_library(VuexInterface);
	}

	_set_store_library(store) {
		this.store = store;
	}

	set(entity, data, expiration_date = null, is_permanent = null) {
		let props = {
			entity: entity,
			store: {
				data: data
			}
		};
		if (expiration_date) {
			props.store.expiration_date = expiration_date;
		}
		if (is_permanent) {
			props.store.is_permanent = true;
		}
		this.store.commit("set_entity", props);
	}

	get(entity, key = null) {
		let result = this.store.getters.get_full_entity(entity);
		if (
			!result ||
			(result.expiration_date && moment(result.expiration_date) < moment())
		)
			return undefined;
		else if (result.data) {
			if (key) {
				if (result.data[key] === undefined) return undefined;
				else if (result.data[key].expiration_date) {
					if (moment(result.data[key].expiration_date) > moment())
						return result.data[key].data;
					else return undefined;
				} else return result.data[key];
			} else {
				return result.data;
			}
		} else return undefined;
	}

	find_by_property(entity_name, property_name, property_value) {
		const entity_values = this.get(entity_name);

		return entity_values
			? entity_values.find((value) => value[property_name] === property_value)
			: null;
	}

	update_by_key(entity, key, data, expiration_date = null) {
		this.store.commit("update_entity_by_key", {
			entity: entity,
			key: key,
			data: expiration_date
				? { data: data, expiration_date: moment(expiration_date).toDate() }
				: data
		});
	}

	update_or_create_by_property(
		entity_name,
		property_name,
		property_value,
		new_data
	) {
		var entity_data = this.get(entity_name);
		if (!entity_data) {
			return this.set(entity_name, [new_data]);
		}

		if (Array.isArray(entity_data)) {
			var found_in_array = false;

			for (let index in entity_data) {
				if (entity_data[index][property_name] === property_value) {
					entity_data[index] = new_data;
					found_in_array = true;
					break;
				}
			}

			if (!found_in_array) {
				entity_data.push(new_data);
			}
		}

		const entity_expiration_date =
			this._get_entity_expiration_date(entity_name);
		this.set(entity_name, entity_data, entity_expiration_date);
	}

	delete(entity_name, index) {
		this.store.commit("remove_entity_index", {
			entity: entity_name,
			index: index
		});
	}

	delete_by_property(entity_name, property_name, property_value) {
		const entity_values = this.get(entity_name);

		let index_to_delete = [];
		for (let index in entity_values) {
			if (entity_values[index][property_name] === property_value)
				index_to_delete.push(index);
		}

		index_to_delete.forEach((index) => this.delete(entity_name, index));
	}

	delete_entity_values(entity_name) {
		this.store.commit("remove_entity_values", { entity: entity_name });
	}

	delete_all_entities(include_permanent_entities = null) {
		if (include_permanent_entities) {
			this.store.commit("remove_all_entities");
		} else {
			const all_entities_data = this.store.getters.all_entities_data;

			for (let entity_name in all_entities_data) {
				const entity_data = all_entities_data[entity_name];

				if (!entity_data.is_permanent) {
					this.store.commit("remove_entity", entity_name);
				}
			}
		}
	}

	_get_entity_expiration_date(entity_name) {
		const full_entity_data = this.store.getters.get_full_entity(entity_name);
		return full_entity_data.expiration_date || null;
	}

	/* New functions */
	find_in_array(entity_name, find_by, value) {
		const entity_values = this.get(entity_name);

		return entity_values.find((element) => element[find_by] == value);
	}

	push_to_array(entity_name, element) {
		const array_values = this.get(entity_name);
		const cached_data_is_array = Array.isArray(array_values);

		if (cached_data_is_array) {
			this.store.commit("push_to_array", {
				entity_name: entity_name,
				element_to_push: element
			});
		} else {
			this.set(entity_name, [element]);
		}
	}

	find_and_delete_in_array(entity_name, find_by, value) {
		const entity_values = this.get(entity_name);

		let key = null;
		for (var i = 0; i < entity_values.length; i++) {
			if (entity_values[i][find_by] == value) {
				key = i;
				break;
			}
		}

		if (key != null) {
			this.store.commit("remove_entity_index", {
				entity: entity_name,
				index: key
			});
		}
	}
}
