<template lang="html">
	<div id="main-div" class="full-width full-height p-xs">
		<div class="rounded-s full-width full-height white-bg overflow-hidden">
			<PageHeader id="header" :text="$t('general.add_new')" close_name="app" />
			<div v-if="loading" class="m-t">
				<Loader />
			</div>
			<div v-else id="content">
				<div class="full-width full-height relative">
					<Geosearch
						v-if="
							this.new_asset.coordinates.lat == null &&
							this.new_asset.coordinates.lon == null
						"
						id="geo-search"
						v-on:result_selected="geosearch_results_received"
					/>
					<Map
						:draggable_marker="
							new_asset.coordinates.lat && new_asset.coordinates.lon
								? new_asset.coordinates
								: null
						"
						v-on:draggable_marker_dragged="marker_dragged"
						v-on:map_clicked="map_clicked"
						:fly_to="map_fly_to"
					/>
				</div>
				<div id="form" class="full-width full-height p-l-xs p-r-xs">
					<div v-if="error" class="p-b">
						<Alert :message="error" />
					</div>
					<div id="form-content" class="overflow-y-scroll relative">
						<div class="absolute full-width full-height">
							<NewAssetForm v-model="new_asset" />
						</div>
					</div>
					<div class="p-t-xs p-b-xs text-right border-t">
						<button class="btn btn-primary" v-on:click="create_asset">
							{{ $t("general.save") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import Map from "@sub_components/Map";
import Geosearch from "@sub_components/GeoSearch";

import NewAssetForm from "./NewAssetForm";

import AssetCreatorClass from "@src/asset/application/asset-creator";
import AssetOtherDataUpdaterClass from "@src/asset/application/asset-other-data-updater.js";

const AssetCreator = new AssetCreatorClass();
const AssetOtherDataUpdater = new AssetOtherDataUpdaterClass();

export default {
	name: "NewLocation",
	components: {
		Loader,
		Alert,
		PageHeader,
		NewAssetForm,
		Map,
		Geosearch
	},
	data() {
		return {
			loading: false,
			error: null,
			new_asset: {
				name: null,
				address: null,
				custom_id: null,
				coordinates: {
					lat: null,
					lon: null
				}
			},
			map_fly_to: null
		};
	},
	computed: {
		map_marker() {
			if (this.new_asset.coordinates.lat && this.new_asset.coordinates.lon) {
				return this.new_asset.coordinates;
			} else {
				return null;
			}
		}
	},
	methods: {
		geosearch_results_received(data) {
			const received_coordinates = data.coordinates;
			this.new_asset.coordinates = received_coordinates;
		},
		marker_dragged(marker_new_coordinates) {
			this.new_asset.coordinates = marker_new_coordinates;
		},
		map_clicked(clicked_data) {
			if (
				this.new_asset.coordinates.lat == null &&
				this.new_asset.coordinates.lon == null
			) {
				this.map_fly_to = {
					coordinates: clicked_data.coordinates,
					zoom: 15
				};

				this.new_asset.coordinates = clicked_data.coordinates;
			}
		},
		async create_asset() {
			this.loading = true;

			try {
				const new_asset = Object.assign({}, this.new_asset);

				const created_asset = await AssetCreator.create_asset(new_asset);

				if (this.new_asset.address) {
					await AssetOtherDataUpdater.update_asset_other_data(
						created_asset.id,
						{
							address: this.new_asset.address
						}
					);
				}

				this.$router.push({
					name: "asset-info",
					params: {
						asset_id: created_asset.id
					}
				});
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#main-div {
	display: flex;
	justify-content: center;
	align-items: center;
}
#main-div > div {
	display: flex;
	flex-flow: column;
}
#content {
	flex: 1 1 auto;
	padding: 15px;
	display: grid;
	grid-template-columns: 50% 50%;
}
#geo-search {
	position: absolute;
	left: 15px;
	top: 15px;
	width: 40%;
	z-index: 1;
}
#form {
	display: flex;
	flex-flow: column;
}
#form-content {
	flex: 1 1 auto;
}
</style>
