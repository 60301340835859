import BackendInterface from "@src/shared/infrastructure/backend-interface";
import LocalObjectConverter from "@src/shared/infrastructure/backend-converters/local-object-converter.js";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_energy_system_custom_config(
		user_id,
		energy_system_id,
		energy_syste_custom_config
	) {
		const url =
			"/api/admin/energy-data/" +
			user_id +
			"/energy-systems/" +
			energy_system_id +
			"/custom-config";
		const backend_data = await this.put(url, {
			body_params: LocalObjectConverter(energy_syste_custom_config)
		});

		const local_data = BackendObjectConverter(backend_data);
		return local_data;
	}
}
