import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_energy_data_first_date(asset_id) {
		const url =
			"/api/asset/" + asset_id + "/energy-data/processed-data-first-day";
		const backend_result = await this.get(url);

		return backend_result.historicalFirstDay
			? new Date(backend_result.historicalFirstDay)
			: null;
	}

	async get_asset_energy_data_last_date(asset_id) {
		const result = await this.get(
			"/api/asset/" + asset_id + "/energy-data/processed-data-last-day"
		);

		return result.historicalLastDay ? new Date(result.historicalLastDay) : null;
	}
}
