<template>
	<div class="p-l p-r p-b">
		<div id="general-table">
			<div class="white-bg rounded-xs p-sm">
				<div class="grey1-color">
					<span class="font-700 font-l">
						{{ $t("pv_main_panels.current_power") }}
					</span>
					<span v-if="current_power_time" class="m-l font-s">
						{{ $format_date_to_day_hour_minute(current_power_time) }}
					</span>
				</div>
				<div class="om-box-content rounded-xs">
					<AssetCurrentPower
						v-on:current_power_updated="current_power_updated"
						:asset="asset"
					/>
				</div>
			</div>

			<div class="white-bg rounded-xs p-sm">
				<div class="flex-vertical-center grey1-color">
					<div>
						<span class="font-700 font-l">
							{{ $t("pv_main_panels.monthly_accumulated") }}
						</span>
						<span v-if="monthly_accumulated_shown_month" class="m-l">
							{{ monthly_accumulated_shown_month }}
						</span>
					</div>
					<div v-if="last_month_enfery_has_negative_number" class="m-l-xs">
						<Tooltip
							:tooltip_text="$t('pv_main_panels.monthly_accumulated_tooltip')"
							:width_in_px="300"
							position="bottom"
						>
						</Tooltip>
					</div>
					<div class="clear"></div>
				</div>
				<div class="om-box-content rounded-xs">
					<PvMainPanelEnergyAggregated
						v-if="last_month_energy"
						:energy_aggregated_data="last_month_energy"
					/>
					<div v-else>
						<Alert type="info" message="general.errors.no_data_available" />
					</div>
				</div>
			</div>

			<div class="flex-auto-full-height-content gap-s">
				<div class="header">
					<AssetDaylight :asset_id="asset.id" />
				</div>
				<div class="content">
					<AssetCurrentDayAlertsSummary :asset_id="asset.id" />
				</div>
			</div>

			<div class="white-bg rounded-xs p-sm">
				<div class="font-700 font-l grey1-color">
					{{ $t("general.location") }}
				</div>
				<div class="om-box-content rounded-xs">
					<AssetImage :asset="asset" />
				</div>
			</div>
			<div class="white-bg rounded-xs p-sm">
				<div class="grey1-color">
					<span class="font-700 font-l">
						{{
							$t(
								"asset_energy_panel." +
									(asset_latest_data_date_is_today
										? "current_day_power"
										: "latest_data_available")
							)
						}}
					</span>
					<span
						v-if="!asset_latest_data_date_is_today && latest_data_date"
						class="font-s m-l-sm"
					>
						{{ $format_date_to_day(latest_data_date) }}
					</span>
				</div>
				<div class="om-box-content rounded-xs">
					<AssetLastDayEnergyMeasurementsChart
						:asset_id="asset.id"
						v-on:data_updated="asset_last_day_measurements_updated"
					/>
				</div>
			</div>
			<div class="flex-auto-full-height-content white-bg rounded-xs p-sm">
				<div class="header grey1-color p-b">
					<span class="font-700 font-l">
						{{ $t("pv_main_panels.total_savings") }}
					</span>
					<span v-if="asset_monthly_energy_data" class="m-l font-s">
						{{ $format_date_to_day(asset_monthly_energy_data[0].time) }} -
						{{
							$format_date_to_day(
								asset_monthly_energy_data[asset_monthly_energy_data.length - 1]
									.time
							)
						}}
					</span>
				</div>
				<div class="content">
					<AssetTotalSavings
						:asset="asset"
						:monthly_aggregated_energy="asset_monthly_energy_data"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Map from "@sub_components/Map";

import AssetCurrentPower from "@sub_components/AssetCurrentPower/AssetCurrentPower.vue";
import PvMainPanelEnergyAggregated from "./PvMainPanelEnergyAggregated";
import AssetTotalSavings from "./AssetTotalSavings";
import AssetDaylight from "./AssetDaylight.vue";
import AssetCurrentDayAlertsSummary from "./AssetCurrentDayAlertsSummary.vue";
import AssetImage from "./AssetImage";
import AssetLastDayEnergyMeasurementsChart from "../../../../sub_components/location-components/AssetLastDayEnergyMeasurementsChart.vue";

import moment from "moment";

export default {
	name: "AssetEnergyMainPanelGeneral",
	props: {
		asset: Object,
		asset_monthly_energy_data: Array || null
	},
	components: {
		Alert,
		AssetCurrentPower,
		PvMainPanelEnergyAggregated,
		AssetTotalSavings,
		Map,
		AssetDaylight,
		AssetCurrentDayAlertsSummary,
		AssetImage,
		AssetLastDayEnergyMeasurementsChart
	},
	data() {
		return {
			current_power_time: null,
			asset_latest_data_date_is_today: null,
			latest_data_date: null,
			accumulated: {
				data: null,
				loading: false,
				error: null
			}
		};
	},
	computed: {
		last_month_energy() {
			if (this.asset_monthly_energy_data) {
				const total_aggregated_data = this.asset_monthly_energy_data;
				const last_month_data =
					total_aggregated_data[total_aggregated_data.length - 1];

				return last_month_data;
			} else {
				return null;
			}
		},
		last_month_enfery_has_negative_number() {
			if (!this.last_month_energy) return null;

			let last_month_energy = Object.assign({}, this.last_month_energy);
			delete last_month_energy.time;

			for (let key in last_month_energy) {
				const value = last_month_energy[key];
				if (value < 0) return true;
			}

			return false;
		},
		monthly_accumulated_shown_month() {
			if (!this.last_month_energy) return null;

			const last_month_energy_time = this.last_month_energy.time;
			const formated_last_month_energy_month = moment(
				last_month_energy_time
			).format("YYYY-MM");

			return formated_last_month_energy_month;
		}
	},
	methods: {
		current_power_updated(data) {
			if (data && data.time) this.current_power_time = data.time;
		},
		asset_last_day_measurements_updated(data) {
			this.asset_latest_data_date_is_today = data.latest_data_date_is_today;
			this.latest_data_date = data.latest_data_date;
		}
	}
};
</script>

<style scoped>
#general-table {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	row-gap: 15px;
	column-gap: 15px;
}
.om-box-content {
	height: 325px;
}
</style>
