export default (backend_object, custom_props_names_convertions = {}) => {
	const convert_camel_case_to_snake_case = (str) =>
		str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

	const convert_object = (object) => {
		let result = {};
		for (let backend_key in object) {
			if (
				custom_props_names_convertions[backend_key] &&
				custom_props_names_convertions[backend_key].ignore
			)
				continue;

			const local_prop_name =
				custom_props_names_convertions[backend_key] &&
				custom_props_names_convertions[backend_key].local_name
					? custom_props_names_convertions[backend_key].local_name
					: convert_camel_case_to_snake_case(backend_key);

			let value = object[backend_key];

			if (value != null) {
				if (
					custom_props_names_convertions[backend_key] &&
					custom_props_names_convertions[backend_key].formatter
				)
					value = custom_props_names_convertions[backend_key].formatter(value);
				else if (typeof value == "object" && !Array.isArray(value))
					value = convert_object(value);
				else if (local_prop_name == "time") value = new Date(value);
			}

			result[local_prop_name] = value;
		}

		return result;
	};

	let local_obj = Array.isArray(backend_object)
		? backend_object.map((item) => convert_object(item))
		: convert_object(backend_object);

	return local_obj;
};
