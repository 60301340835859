<template lang="html">
	<div class="display-grid gap-s">
		<div class="display-grid gap-xs">
			<span>Key ID</span>
			<TextInput v-model="key_id" />
		</div>
		<div class="display-grid gap-xs">
			<span>Key secret</span>
			<TextInput v-model="key_secret" />
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";

export default {
	name: "SolisLoginForm",
	props: { editing: Boolean },
	components: {
		TextInput
	},
	data() {
		return {
			key_id: null,
			key_secret: null
		};
	},
	methods: {
		emit_value() {
			this.$emit("input", {
				key_id: this.key_id,
				key_secret: this.key_secret
			});
		}
	},
	watch: {
		key_id: function () {
			this.emit_value();
		},
		key_secret: function () {
			this.emit_value();
		}
	}
};
</script>

<style lang="css" scoped></style>
