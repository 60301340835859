//import Vue from "vue";
import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import { env } from "@config";

const vuexPersist = new VuexPersist({
	key: "sentinel-frontend-app",
	storage: env == "test" ? null : window.localStorage
});

//Vue.use(Vuex);

// export default new Vuex.Store({
export default createStore({
	state: {},
	mutations: {
		set_entity(state, data) {
			state[data.entity] = data.store;
		},
		update_entity_by_key(state, data) {
			if (!state.hasOwnProperty(data.entity)) state[data.entity] = { data: {} };
			state[data.entity].data[data.key] = data.data;
		},
		push_to_array(state, data) {
			const entity_name = data.entity_name;
			const element_to_push = data.element_to_push;

			state[entity_name].data.push(element_to_push);
		},
		remove_entity_index(state, data) {
			const entity_name = data.entity;
			const index_to_remove = data.index;

			if (!state.hasOwnProperty(entity_name)) return null;

			const entity_data = state[entity_name].data;
			const entity_data_is_array = Array.isArray(entity_data);

			if (entity_data_is_array) {
				entity_data.splice(index_to_remove, 1);
			} else {
				delete entity_data[index_to_remove];
			}
		},
		remove_entity_values(state, data) {
			const entity_name = data.entity;

			delete state[entity_name];
		},
		remove_all_entities(state) {
			for (let key in state) {
				delete state[key];
			}
		},
		remove_entity(state, entity_name) {
			if (state.hasOwnProperty(entity_name)) delete state[entity_name];
		}
	},
	actions: {},
	getters: {
		/*providers(state) {
			return state.providers;
		},
		get_entity: state => data => {
			if (!state[data.entity]) return null;
			else if (data.id && state[data.entity].data[data.id])
				return state[data.entity].data[data.id];
			else return state[data.entity];
		},*/
		get_full_entity: (state) => (entity) => {
			return state[entity] || null;
		},
		all_entities_data(state) {
			return state;
		}
	},
	plugins: [vuexPersist.plugin]
});
