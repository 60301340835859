import EnergySystemCustomConfigUpdaterInterfaceClass from "../infrastructure/energy-system-custom-config-updater-interface.js";
import EnergySystemCustomConfigValidator from "../domain/energy-system-custom-config-validator";

import EnergySystemsCustomConfigStoreUtil from "./utils/energy-systems-custom-config-store-util.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface =
			new EnergySystemCustomConfigUpdaterInterfaceClass();
		this.store_util = EnergySystemsCustomConfigStoreUtil;
		this.validator = EnergySystemCustomConfigValidator;
	}

	async update_energy_system_custom_config(
		user_id,
		energy_system_id,
		energy_syste_custom_config
	) {
		const validated_local_data = this.validator.validate(
			energy_syste_custom_config
		);

		try {
			const interface_data =
				await this.backend_interface.update_energy_system_custom_config(
					user_id,
					energy_system_id,
					validated_local_data
				);
			const validated_data = this.validator.validate(interface_data);

			await this.store_util.add_or_update_item({
				energy_system_id: energy_system_id,
				data: validated_data
			});

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_update_energy_system_custom_config",
				"general.errors.general_error",
				e
			);
		}
	}
}
