import AssetEnergyAvailableDateFinderInterfaceClass from "@src/asset-energy-data/infrastructure/asset-energy-available-date-finder-interface";
import AssetEnergyAvailableDateValidator from "@src/asset-energy-data/domain/asset-energy-available-date-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new AssetEnergyAvailableDateFinderInterfaceClass();
		this.validator = AssetEnergyAvailableDateValidator;
		this.store_interface = new StoreInterfaceClass();
	}

	async get_asset_energy_data_first_date(asset_id) {
		const entity_name = "assets_energy_data_first_date";
		var asset_energy_data_first_date = this.store_interface.get(
			entity_name,
			asset_id
		);

		if (!asset_energy_data_first_date) {
			try {
				const result =
					await this.backend_interface.get_asset_energy_data_first_date(
						asset_id
					);

				const validated_result = this.validator.validate(result);

				this.store_interface.update_by_key(
					entity_name,
					asset_id,
					validated_result,
					moment().add(1, "days").toDate()
				);

				asset_energy_data_first_date = this.store_interface.get(
					entity_name,
					asset_id
				);
			} catch (e) {
				throw new AppError(
					"error_get_asset_energy_data_first_date",
					"general.errors.general_error",
					e
				);
			}
		}

		return asset_energy_data_first_date;
	}

	async get_asset_energy_data_last_date(asset_id) {
		const entity_name = "assets_processed_energy_data_last_day";

		var cached_result = this.store_interface.get(entity_name, asset_id);
		if (cached_result) {
			return cached_result;
		}

		try {
			const asset_processed_energy_data_last_day =
				await this.backend_interface.get_asset_energy_data_last_date(asset_id);

			const validated_result = this.validator.validate(
				asset_processed_energy_data_last_day
			);

			if (validated_result)
				this.store_interface.update_by_key(
					entity_name,
					asset_id,
					validated_result,
					moment().add("5", "hours").toDate()
				);

			return validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_data_last_date",
				"general.errors.general_error",
				e
			);
		}
	}
}
