import EnergySystemsFinderInterfaceClass from "../infrastructure/asset-energy-systems-finder-interface";
import EnergySystemFinderValidator from "../domain/energy-system-finder-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new EnergySystemsFinderInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
		this.validator = EnergySystemFinderValidator;
	}

	async get_asset_energy_systems(asset_id, force_download = null) {
		try {
			const store_entity_name = "assets_energy_systems";
			var result = this.store_interface.get(store_entity_name, asset_id);

			if (!result || force_download) {
				const interface_data =
					await this.backend_interface.get_asset_energy_systems(asset_id);

				result = [];
				interface_data.forEach((item) => {
					const validated_item = this.validator.validate(item);
					result.push(validated_item);
				});

				this.store_interface.update_by_key(
					store_entity_name,
					asset_id,
					result,
					moment().endOf("day").toDate()
				);
			}

			return result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_systems",
				"general.errors.general_error",
				e
			);
		}
	}
}
