import Joi from "joi";
import Validator from "@validators/validator";

const pv_data_schema = Joi.object({
	peak_power: Joi.number().min(0).required(),
	nominal_power: Joi.number().min(0),
	tracking: Joi.string().valid("fixed", "1-axis", "2-axis").required(),
	tilt: Joi.number().min(0).max(90),
	azimuth: Joi.number().min(0).max(360),
	losses: Joi.number().min(0).max(100),
	pv_panel_models: Joi.array().items(
		Joi.object({
			id: Joi.string().required(),
			quantity: Joi.number().min(0).required()
		})
	),
	estimated_production: Joi.object({
		panel_degradation: Joi.number().min(0).required(),
		data: Joi.array().items(
			Joi.object({
				month: Joi.number().min(1).max(12),
				value: Joi.number().min(0)
			})
		)
	}),

	solar_type: Joi.string().valid("single", "shared", null),
	facility_type: Joi.string().valid(
		"industrial",
		"commercial",
		"residential",
		null
	),
	voltage: Joi.number().min(0),
	energy_to_grid_price: Joi.number().min(0),
	distribution_company: Joi.string(),
	land_registry_reference: Joi.string(),
	connection_type: Joi.string().valid(
		"wifi",
		"wifi_pcl_or_repeater",
		"ethernet",
		"4g",
		"other",
		"no_communication",
		null
	),
	system_id_in_grid: Joi.string()
});
export default new Validator(pv_data_schema, "entities.energy_system.pv_data.");
