import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import MultilingualPlugin from "./vue-plugins/multilingual-plugin";
import DataFormatterPlugin from "./vue-plugins/data-formatter-pluging";
import FileExportPluging from "./vue-plugins/file-export-plugin";
import ResponsivePlugin from "./vue-plugins/responsive-plugin";
import EnergyDataPlugin from "./vue-plugins/energy-data-plugin";

import CustomIcon from "@sub_components/custom-icon/CustomIcon";
import Tooltip from "@sub_components/Tooltip";

createApp(App)
	.use(router)
	.use(MultilingualPlugin)
	.use(DataFormatterPlugin)
	.use(FileExportPluging)
	.use(ResponsivePlugin)
	.use(EnergyDataPlugin)
	.component("Icon", CustomIcon)
	.component("Tooltip", Tooltip)
	.mount("#app");
